import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../../FrontendHelper";
import { Checkbox, Col, Row, Radio, DatePicker } from "antd";
import moment from "moment";
import LoadingComponent from "../../LoadingComponent";
import { toast } from "react-toastify";

const mapStateToProps = (store) => {
  const {
    buttonText,
    isEnabledUsers,
    searchUsersList,
    isLoadingOnLogin,
    isOperationSuccessful,
  } = store.mainState;
  return {
    buttonText,
    isEnabledUsers,
    searchUsersList,
    isLoadingOnLogin,
    isOperationSuccessful,
  };
};

function AddMerchantCategory({
  buttonText,
  searchUsersList,
  isLoadingOnLogin,
  isOperationSuccessful,
  isEnabledUsers,
  restaurantId,
}) {
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState("");

  const dispatch = useDispatch();
  const clearFileInputRef = useRef(null);

  const handleAddCategory = (e) => {
    e.preventDefault();
    if (!categoryImage || !categoryName) {
      return toast.error("Please fill all the fields !!");
    }
    const merchantFormData = new FormData();
    merchantFormData.append("categoryName", categoryName);
    merchantFormData.append("image", categoryImage);
    dispatch({
      type: "ADD_MERCHANT_CATEGORY_REQUEST",
      payload: merchantFormData,
    });
  };

  useEffect(() => {
    if (isEnabledUsers === "b") {
      setCategoryName("");
      clearFileInputRef.current.value = "";
    }
  }, [isEnabledUsers]);

  return (
    <div>
      <div className="Modal--heading">Add Category</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Category Image : </label>
              <input
                ref={clearFileInputRef}
                type="file"
                onChange={(e) => setCategoryImage(e.target.files[0])}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Category Name: </label>
              <input
                type="text"
                value={categoryName}
                placeholder="Category Name"
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </p>
          </div>

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleAddCategory}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddMerchantCategory);
