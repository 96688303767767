import React from "react";
import Layout from "../../Layout/Layout";
import { Alert, Col, Divider, Row, Popconfirm, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CampaingHeading from "../Campaign/CampaingHeading";
import { Collapse } from "antd";
import PreOrderHeading from "./Headings/PreOrderHeading";
import LoadingComponent from "../LoadingComponent";
import { RiDeleteBack2Fill } from "react-icons/ri";
import { BsX } from "react-icons/bs";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import DatePicker from "react-datepicker";
import GetValidLocation from "../PreOrder/GetValidLocation";
import moment from "moment";
import dayjs from "dayjs";

const { Panel } = Collapse;

function AddPreOrderBatch() {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [postsPerPage2, setPostsPerPage2] = useState(10);
  const {
    allPreOrderList,
    allPreOrderRequestedList,
    isLoadingOnLogin,
    isBatchCreated,
    preOrderTimeSlotList,
  } = useSelector((state) => state.mainState);
  const [preOrderId, setPreOrderId] = useState(null);
  const [myBatchedList, setMyBatchedList] = useState([]);
  const [detailShowingItem, setDetailsShhowingItem] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [pickUpLocation, setPickupLocation] = useState("");

  const [filterPreorderDate, setFilterPreorderDate] = useState(moment.now());
  const [timeSlot, setTimeSlot] = useState(
    filterPreorderDate
      ? preOrderTimeSlotList?.filter(
          (timeSlot) =>
            timeSlot.slotDate == dayjs(filterPreorderDate).format("YYYY-MM-DD")
        )
      : preOrderTimeSlotList
  );
  const [selectedPreOrderTimeSlot, setSelectedPreOrderTimeSlot] = useState("");
  const history = useHistory();

  let pageNumbers1 = [];
  for (let i = 1; i <= allPreOrderRequestedList.totalPage; i++) {
    pageNumbers1.push(i);
  }
  let pageNumbers2 = [];
  for (let i = 1; i <= allPreOrderList.totalPage; i++) {
    pageNumbers2.push(i);
  }
  const handlelatLong = (myvalue) => {
    setLatitude(myvalue.lat);
    setLongitude(myvalue.lng);
  };
  const handleAddress = (address) => {
    setPickupLocation(address);
  };

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // get current posts
  const indexOfLastPost2 = currentPage2 * postsPerPage2;
  const indexOfFirstPost2 = indexOfLastPost2 - postsPerPage2;

  const [batchData, setBatchData] = useState({
    packageName: "",
    commissionAmount: null,
    batchNote: "",
    maxDropOffRadius: "",
    bonusPerOrder: "",
    pickUpDate: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setBatchData({ ...batchData, [name]: value });
  };

  const [showCreateBatchModal, setShowCreateBatchModal] = useState(false);

  const confirm = (e) => {
    e.preventDefault();
    let myLocalBatchedList = myBatchedList.reduce((myArr, item) => {
      myArr.push({
        batchId: item.preOrderId,
        itemType: "PRE_ORDER",
      });
      return myArr;
    }, []);

    if (batchData.commissionAmount === null) {
      alert("Commission amount is mandatory !!");
    } else if (pickUpLocation === "") {
      toast.error("Pick up location in mandatory");
    } else if (latitude === "") {
      toast.error("Latitude and longitude is undefined !!!");
    } else {
      dispatch({
        type: "CREATE_NEW_BATCH_REQUEST",
        payload: {
          packageName: batchData.packageName,
          packageItems: myLocalBatchedList,
          commissionAmount: batchData.commissionAmount,
          pickUpLocation: pickUpLocation,
          pickUpLongitude: longitude,
          pickUpLatitude: latitude,
          pickUpDate: batchData.pickUpDate,
          bonusPerOrder: batchData.bonusPerOrder,
          batchNote: batchData.batchNote,
          maxDropOffRadius: batchData.maxDropOffRadius,
        },
      });
    }
  };

  const dispatch = useDispatch();

  const handleIndividualCheck = (item, status) => {
    if (status) {
      setMyBatchedList([...myBatchedList, item]);
    } else {
      let xx = myBatchedList.filter(
        (bachedItem) => bachedItem.preOrderId !== item.preOrderId
      );
      setMyBatchedList(xx);
    }
  };
  const handleEverythingCheck = (status) => {
    if (status) {
      setMyBatchedList(allPreOrderRequestedList?.data);
    } else {
      setMyBatchedList([]);
    }
  };
  const handleRemoveBatchedOrder = (item) => {
    toast.warning("Batched item removed !!");
    let i = document.getElementById(item.preOrderId);
    i.checked = false;

    let xx = myBatchedList.filter(
      (bachedItem) => bachedItem.preOrderId !== item.preOrderId
    );
    setMyBatchedList(xx);
  };

  useEffect(() => {
    setTimeSlot(
      filterPreorderDate
        ? preOrderTimeSlotList?.filter(
            (timeSlot) =>
              timeSlot.slotDate ==
              dayjs(filterPreorderDate).format("YYYY-MM-DD")
          )
        : preOrderTimeSlotList
    );

    dispatch({
      type: "GET_ALL_PREORDER_LIST_REQUEST",
      payload: { postsPerPage, currentPage },
    });
  }, [postsPerPage, currentPage, preOrderTimeSlotList, filterPreorderDate]);

  useEffect(() => {
    let deliveryTime = "";
    let sortingParam = "descending";
    let postsPerPage = postsPerPage2;
    let currentPage = currentPage2;
    if (preOrderId) {
      dispatch({
        type: "GET_REQUESTED_PREORDER_LIST_BATCH_REQUEST",
        payload: {
          postsPerPage,
          currentPage,
          deliveryTime,
          sortingParam,
          preOrderId,
          selectedPreOrderTimeSlot,
        },
      });
      dispatch({
        type: "FETCH_PRE_ORDER_TIME_SLOT_REQUEST",
        payload: { preOrderId, postsPerPage, currentPage },
      });
    }
  }, [preOrderId, currentPage2, filterPreorderDate, selectedPreOrderTimeSlot]);
  useEffect(() => {
    if (isBatchCreated) {
      setTimeout(() => {
        history.push("/batch-package");
      }, 1000);
    }
  }, [isBatchCreated]);

  console.log("time Slot", selectedPreOrderTimeSlot);

  const preOrderListTable = () => (
    <>
      <Collapse bordered={false} accordion>
        <div className="create-new-batch-filter">
          <div
            style={{
              display: "flex",
              gap: "2rem",
              justifyContent: "space-between",
            }}
          >
            <p style={{ display: "flex" }}>
              <label htmlFor="">
                Filter By Date :{" "}
                <DatePicker
                  placeholderText="Enter your date"
                  className="filter-by__Date--input"
                  dateFormat="yyyy-MM-dd"
                  selected={filterPreorderDate}
                  onChange={(date) => setFilterPreorderDate(date)}
                />
              </label>{" "}
            </p>
            {/* <p style={{ display: "flex" }}>
              <label htmlFor="">
                Filter By Location :{" "}
                <select className="filter-by__Date--input">
                  <option>Kathmandu</option>
                  <option>Kathmandu</option>
                  <option>Kathmandu</option>
                  <option>Kathmandu</option>
                </select>
              </label>
            </p> */}
            <p>
              {" "}
              {timeSlot?.length > 0 && (
                <span style={{ marginLeft: "0.4rem" }}>
                  <label htmlFor="">Time Slot : </label>{" "}
                  <select
                    defaultValue={null}
                    placeholder="Select Time Slot"
                    name="pageSize"
                    onChange={(e) =>
                      setSelectedPreOrderTimeSlot(e.target.value)
                    }
                    style={{ width: "170px" }}
                  >
                    <option selected value="">
                      Select Time Slot
                    </option>
                    {timeSlot[0].timeResponses?.map((slot) => (
                      <option
                        onChange={() => {
                          setSelectedPreOrderTimeSlot(slot.preOrderTimeSlotId);
                        }}
                        value={slot.preOrderTimeSlotId}
                      >
                        {" "}
                        {slot.startTime} - {slot.endTime}
                      </option>
                    ))}
                  </select>{" "}
                </span>
              )}{" "}
            </p>
          </div>
        </div>
        {allPreOrderList !== undefined &&
          allPreOrderList.data?.map((item, index) => (
            <Panel
              key={index + 1}
              header={
                <PreOrderHeading item={item} setPreOrderId={setPreOrderId} />
              }
            >
              <div>
                <p
                  className="privacy-loading--onFetching"
                  style={{ width: "100%" }}
                >
                  {isLoadingOnLogin && <LoadingComponent />}
                </p>
                {allPreOrderRequestedList?.data?.length >= 1 ? (
                  <>
                    <table style={{ marginLeft: "2vw" }}>
                      <thead>
                        <tr style={{ backgroundColor: "pink" }}>
                          <th className="campaignItem--image">
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleEverythingCheck(e.target.checked)
                              }
                            />
                          </th>
                          <th className="campaignItem--image">Order Id</th>
                          <th className="campaignItem--image">Customer Name</th>
                          <th className="campaignItem--image">
                            Contact Number
                          </th>
                          <th className="campaignItem--name">
                            Delivery Location
                          </th>
                          <th className="campaignItem--desc">Delivery Time</th>
                        </tr>
                      </thead>

                      <tbody>
                        {allPreOrderRequestedList?.data?.map((item) => (
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                id={item.preOrderId}
                                checked={myBatchedList.includes(item)}
                                onChange={(e) =>
                                  handleIndividualCheck(item, e.target.checked)
                                }
                              />
                            </td>
                            <td>
                              <span>{item.preOrderId}</span>
                            </td>
                            <td>{item.fullName}</td>
                            <td>{item.contactNumber}</td>
                            <td>{item.deliveryLocation}</td>
                            <td>
                              {moment
                                .utc(item.deliveryTime)
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="pagination">
                      <p>
                        Showing {indexOfFirstPost2} to{" "}
                        {indexOfLastPost2 > allPreOrderRequestedList.totalData
                          ? allPreOrderRequestedList.totalData
                          : indexOfLastPost2}{" "}
                        of {allPreOrderRequestedList.totalData} entries
                      </p>
                      <nav aria-label="...">
                        <ul className="pagination">
                          {currentPage2 === 1 ? (
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <BiChevronLeft />
                              </a>
                            </li>
                          ) : (
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <BiChevronLeft
                                  onClick={() =>
                                    setCurrentPage2(currentPage2 - 1)
                                  }
                                />
                              </a>
                            </li>
                          )}
                          {pageNumbers1.map((x) => (
                            <li key={x} className="page-item">
                              <a
                                className="page-link"
                                href="#"
                                onClick={() => setCurrentPage2(x)}
                              >
                                {x}
                              </a>
                            </li>
                          ))}
                          {currentPage2 == pageNumbers1.length ? (
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <BiChevronRight />
                              </a>
                            </li>
                          ) : (
                            <li className="page-item">
                              <a className="page-link" href="#">
                                <BiChevronRight
                                  onClick={() =>
                                    setCurrentPage2(currentPage2 + 1)
                                  }
                                />
                              </a>
                            </li>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "white",
                        height: "50px",
                        width: "maxContent",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 20px",
                        boxShadow:
                          "box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;",
                      }}
                    >
                      No order available in table
                    </div>
                  </div>
                )}
              </div>
            </Panel>
          ))}
      </Collapse>
      <div style={{ backgroundColor: "#FAFAFA" }}>
        <div className="pagination">
          <p>
            Showing {indexOfFirstPost} to{" "}
            {indexOfLastPost > allPreOrderList.totalData
              ? allPreOrderList.totalData
              : indexOfLastPost}{" "}
            of {allPreOrderList.totalData} entries
          </p>
          <nav aria-label="...">
            <ul className="pagination">
              {currentPage === 1 ? (
                <li className="page-item">
                  <a className="page-link" href="#">
                    <BiChevronLeft />
                  </a>
                </li>
              ) : (
                <li className="page-item">
                  <a className="page-link" href="#">
                    <BiChevronLeft
                      onClick={() => setCurrentPage(currentPage - 1)}
                    />
                  </a>
                </li>
              )}
              {pageNumbers2.map((x) => (
                <li key={x} className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => setCurrentPage(x)}
                  >
                    {x}
                  </a>
                </li>
              ))}
              {currentPage == pageNumbers2.length ? (
                <li className="page-item">
                  <a className="page-link" href="#">
                    <BiChevronRight />
                  </a>
                </li>
              ) : (
                <li className="page-item">
                  <a className="page-link" href="#">
                    <BiChevronRight
                      onClick={() => setCurrentPage(currentPage + 1)}
                    />
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );

  const orderList = () => {
    return (
      <div style={{ backgroundColor: "cadetblue", marginTop: "100px" }}>
        {preOrderListTable()}
      </div>
    );
  };
  const batchedList = () => {
    return (
      <div className="bachedList--container">
        <p>Batched Order List</p>
        <div className="bachedList--container__item">
          {myBatchedList?.map((item) => (
            <div
              className="bachedList--container__item--list"
              key={item.preOrderId}
            >
              <p onClick={() => handleRemoveBatchedOrder(item)}>
                <RiDeleteBack2Fill className="riDeleteBack2Fill" />{" "}
              </p>
              <div
                onClick={() => {
                  setShowDetailsModal(true);
                  setDetailsShhowingItem(item);
                }}
              >
                <p>{item.preOrderId}</p>
                <p>{item.deliveryLocation}</p>
                <Divider style={{ margin: "0px" }} />
              </div>
            </div>
          ))}
        </div>
        {myBatchedList.length > 0 && (
          <div className="bachedList--container--button">
            <button onClick={() => setShowCreateBatchModal(true)}>
              Batch Now
            </button>
          </div>
        )}
      </div>
    );
  };
  return (
    <Layout>
      <div>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={18}>{orderList()}</Col>
          <Col span={6}>{batchedList()}</Col>
        </Row>
      </div>
      <div
        className={`${
          showDetailsModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div
          className="delete--admin--container"
          style={{
            justifyContent: "flex-start",
            minWidth: "500px",
            minHeight: "340px",
            maxHeight: "350px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 20px",
            }}
          >
            <h3 style={{ marginTop: "20px" }}>Pre-Order Item Details</h3>
            <h3
              style={{ marginTop: "20px", cursor: "pointer" }}
              onClick={() => setShowDetailsModal(false)}
            >
              <BsX />
            </h3>
          </div>
          <hr />
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "5vw",
            }}
          >
            <p>
              <span>Plan Name :</span>
              {detailShowingItem?.preOrderPlanName}{" "}
            </p>
            <p>
              <span>Customer Name :</span>
              {detailShowingItem?.fullName}
            </p>
            <p>
              <span>Customer Number :</span>
              {detailShowingItem?.contactNumber}
            </p>
            <p>
              <span>Delivery Location :</span>{" "}
              {detailShowingItem?.deliveryLocation}
            </p>
            <p>
              <span>Price : $ </span> {detailShowingItem?.planPrice}
            </p>
          </div>
        </div>
      </div>
      <div
        className={`${
          showCreateBatchModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div
          className="delete--admin--container"
          style={{
            justifyContent: "flex-start",
            minWidth: "600px",
            minHeight: "540px",
            maxHeight: "1000px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 20px",
            }}
          >
            <h3 style={{ marginTop: "20px" }}>Create New Batch</h3>
            <h3
              style={{ marginTop: "20px", cursor: "pointer" }}
              onClick={() => setShowCreateBatchModal(false)}
            >
              <BsX />
            </h3>
          </div>

          <Divider style={{ margin: "0px" }} />
          <div className="Modal--form" style={{ marginTop: "15px" }}>
            <form>
              <div className="batch__Modal--form--title">
                <p>
                  <label htmlFor="">
                    Batch Name :{" "}
                    <input
                      type="text"
                      name="packageName"
                      value={batchData.packageName}
                      placeholder="Batch name"
                      onChange={handleOnChange}
                    />
                  </label>
                </p>
              </div>
              <div className="batch__Modal--form--title">
                <p>
                  <label htmlFor="">
                    Batch Note :{" "}
                    <input
                      type="text"
                      name="batchNote"
                      value={batchData.batchNote}
                      placeholder="Batch Note"
                      onChange={handleOnChange}
                    />
                  </label>
                </p>
              </div>
              <div className="batch__Modal--form--title">
                <p>
                  <label htmlFor="">
                    Bonus Per Order :{" "}
                    <input
                      type="number"
                      name="bonusPerOrder"
                      value={batchData.bonusPerOrder}
                      placeholder="Bonus Per Order"
                      onChange={handleOnChange}
                    />
                  </label>
                </p>
              </div>
              <div className="batch__Modal--form--title">
                <p>
                  <label htmlFor="">
                    Pick Up Date :{" "}
                    <input
                      type="date"
                      name="pickUpDate"
                      value={batchData.pickUpDate}
                      onChange={handleOnChange}
                    />
                  </label>
                </p>
              </div>

              <div className="batch__Modal--form--title">
                <p>
                  <label htmlFor="">
                    Max Drop Radius(KM*) :{" "}
                    <input
                      type="number"
                      name="maxDropOffRadius"
                      placeholder="Max Drop Radius"
                      value={batchData.maxDropOffRadius}
                      onChange={handleOnChange}
                    />
                  </label>
                </p>
              </div>

              <div className="batch__Modal--form--title">
                <p>
                  <label htmlFor="">
                    Commission(%) :{" "}
                    <input
                      type="number"
                      name="commissionAmount"
                      placeholder="Commission"
                      value={batchData.commissionAmount}
                      onChange={handleOnChange}
                    />
                  </label>
                </p>
              </div>
              <div className="batch__Modal--form--title">
                <p style={{ display: "flex" }}>
                  <label htmlFor="">Location : </label>{" "}
                  <GetValidLocation
                    handlelatLong={handlelatLong}
                    handleAddress={handleAddress}
                  />
                </p>
              </div>

              <div className="batch__Modal--form--button">
                <button onClick={confirm}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddPreOrderBatch;
