import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
import GoogleMap2 from "./GoogleMap2";
import TimePicker from "react-time-picker";
import moment from "moment";
import { Radio } from "antd";

const mapStateToProps = (store) => {
  const { updateButtonText, operatingLocationList } = store.mainState;
  return { updateButtonText, operatingLocationList };
};

function UpdateRestaurant({ updateButtonText, item, operatingLocationList }) {
  const clearFileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isSuccessImgUrl, setIsSuccessImgUrl] = useState(false);
  const [restaurantName, setRestaurantName] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [commissionRate, setCommissionRate] = useState("");
  const [slogan, setSlogan] = useState("");
  const [restEmail, setRestEmail] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [contactPersonNo, setContactNo] = useState("");
  const [ContactPersonName, setContactPersonName] = useState("");
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  const [operatingLocation, setOperatingLocation] = useState(null);
  const [isVisibleForUser, setIsVisibleForuser] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", image);
    setLoading(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_FAKE}/foocery/v1/api/admin/restaurant/add-image`,
      // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/restaurant/add-image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setImageUrl(response.data.imageUrl);
        setLoading(false);
        setIsSuccessImgUrl(!isSuccessImgUrl);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [image]);
  useEffect(() => {
    let postsPerPage = 50;
    let currentPage = 1;
    dispatch({
      type: "FETCH_OPERATING_LOCATION_REQUEST",
      payload: { postsPerPage, currentPage },
    });
  }, []);
  const handlelatLong = (myvalue) => {
    setLatitude(myvalue.lat);
    setLongitude(myvalue.lng);
  };
  const handleAddress = (address) => {
    setLocation(address);
  };
  const handleLoyalitySubmit = (e) => {
    e.preventDefault();
    let openingTimeX = `2021-01-15 ${openingTime}`;
    let closingTimeX = `2021-01-15 ${closingTime}`;
    let m = new Date(openingTimeX).toUTCString().split(" ");
    let n = new Date(closingTimeX).toUTCString().split(" ");
    if (imageUrl !== "") {
      dispatch({
        type: "UPDATE_RESTAURANTS_REQUEST",
        payload: {
          id: item.id,
          restaurantName: restaurantName,
          location: location,
          latitude: Number(latitude),
          longitude: Number(longitude),
          slogan: slogan,
          description: description,
          commissionRate: Number(commissionRate),
          email: restEmail,
          imageUrl: imageUrl,
          contactPersonName: ContactPersonName,
          contactPersonPhone: contactPersonNo,
          openingTime: m[4],
          closingTime: n[4],
          operatingLocation: operatingLocation,
          isVisibleForUsers: isVisibleForUser,
        },
      });
    } else {
      dispatch({
        type: "UPDATE_RESTAURANTS_REQUEST",
        payload: {
          id: item.id,
          restaurantName: restaurantName,
          location: location,
          latitude: Number(latitude),
          longitude: Number(longitude),
          slogan: slogan,
          description: description,
          commissionRate: Number(commissionRate),
          email: restEmail,
          contactPersonName: ContactPersonName,
          contactPersonPhone: contactPersonNo,
          openingTime: m[4],
          closingTime: n[4],
          operatingLocation: operatingLocation,
          isVisibleForUsers: isVisibleForUser,
        },
      });
    }

    clearFileInputRef.current.value = "";
  };
  useEffect(() => {
    setRestaurantName(item.restaurantName);
    setLocation(item.location);
    setDescription(item.description);
    setCommissionRate(item.commissionRate);
    setSlogan(item.slogan);
    setRestEmail(item.email);
    setLatitude(item.latitude);
    setLongitude(item.longitude);
    setPhoneNo(item.phoneNumber);
    setContactNo(item.contactPersonPhone);
    setContactPersonName(item.contactPersonName);
    setOpeningTime(item.openingTime);
    setClosingTime(item.closingTime);
    setOperatingLocation(item.operatingLocation);
    setIsVisibleForuser(item.isVisibleForUsers);
  }, [item]);
  useEffect(() => {
    let x = getLocalDateTime(`2021-01-03 ${item.openingTime}`).split(" ")[1];

    let y = getLocalDateTime(`2020-01-01 ${item.closingTime}`).split(" ")[1];
    setOpeningTime(x);
    setClosingTime(y);
  }, [item]);
  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };
  const onChange1 = (e) => {
    setIsVisibleForuser(e.target.value);
  };
  return (
    <div>
      <div className="Modal--heading">Update Merchant</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Image : </label>
              <input
                type="file"
                placeholder="Email"
                ref={clearFileInputRef}
                name="image"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Restaurant Name: </label>
              <input
                type="text"
                value={restaurantName}
                placeholder="Restaurant Name"
                onChange={(e) => setRestaurantName(e.target.value)}
              />
            </p>
          </div>
          {/* <div className="Modal--form--title">
                        <p><label htmlFor="title">Restaruant Phone No : </label>
                            <input type="text" value={phoneNo} placeholder="Phone Number" onChange={(e) => setPhoneNo(e.target.value)} />
                        </p>
                    </div> */}
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Contact Person Name : </label>
              <input
                type="text"
                value={ContactPersonName}
                placeholder="Contact Person Name"
                onChange={(e) => setContactPersonName(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Contact Person Phone No : </label>
              <input
                type="text"
                value={contactPersonNo}
                placeholder="Contact Person Phone Number"
                onChange={(e) => setContactNo(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Description: </label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                style={{ padding: "10px", minHeight: "50px" }}
                value={description}
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
              {/* <input type="text" value={description} placeholder="Description" onChange={(e) => setDescription(e.target.value)} /> */}
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Commission Rate(%) : </label>
              <input
                type="text"
                value={commissionRate}
                placeholder="Comission Rate (%)"
                onChange={(e) => setCommissionRate(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Slogan : </label>
              <input
                type="text"
                value={slogan}
                placeholder="Slogan"
                onChange={(e) => setSlogan(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Email : </label>
              <input
                type="email"
                placeholder="Email"
                value={restEmail}
                onChange={(e) => setRestEmail(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <div className="restaurant--Modal--form__time">
              <p>
                <label htmlFor="title">Opening Time : </label>
                <TimePicker
                  format="hh:mm a"
                  onChange={setOpeningTime}
                  name="Opening_Time"
                  value={openingTime}
                  className="myTime--picker"
                  style={{ marginLeft: "20px" }}
                />
              </p>
            </div>
          </div>
          <div className="Modal--form--title">
            <div className="restaurant--Modal--form__time">
              <p>
                <label htmlFor="title">Closing Time : </label>
                <TimePicker
                  format="hh:mm a"
                  onChange={setClosingTime}
                  name="Opening_Time"
                  value={closingTime}
                  className="myTime--picker"
                />
              </p>
            </div>
          </div>

          <div>
            <GoogleMap2
              handlelatLong={handlelatLong}
              handleAddress={handleAddress}
              prevAddress={location}
              preLat={latitude}
              prevLngd={longitude}
            />
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Location : </label>
              <input
                type="text"
                value={location}
                disabled
                placeholder="Location"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Latitude : </label>
              <input
                type="text"
                placeholder="Latitude"
                value={latitude}
                disabled
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Longitude : </label>
              <input
                type="text"
                placeholder="Longitude"
                value={longitude}
                disabled
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Operating Location : </label>
              <select
                value={operatingLocation}
                onChange={(e) => setOperatingLocation(e.target.value)}
              >
                <option value="">Select</option>
                {operatingLocationList.data?.map((location) =>
                  location.isActive ? (
                    <option key={location.id} value={location.locationName}>
                      {location.locationName}
                    </option>
                  ) : null
                )}
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is Visible For User: </label>
              <Radio.Group
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onChange1}
                value={isVisibleForUser}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleLoyalitySubmit}
            >
              {updateButtonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(UpdateRestaurant);
