import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
// import { useIdleTimer } from 'react-idle-timer'
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import { BsEyeFill } from "react-icons/bs";
import { BsX } from "react-icons/bs";
import OrderDetails from "./OrderDetails";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import moment from "moment";
import { Select } from "antd";
const { Option } = Select;

const mapStateToProps = (store) => {
  const {
    isLoadingOnLogin,
    orderList,
    isToogleSidebar,
    isEnabledUsers,
    operatingLocationList,
    merchantCategories,
    merchantSubCategories,
  } = store.mainState;
  return {
    isLoadingOnLogin,
    orderList,
    isToogleSidebar,
    isEnabledUsers,
    operatingLocationList,
    merchantCategories,
    merchantSubCategories,
  };
};

function MerchantOrderManagement({
  isLoadingOnLogin,
  orderList,
  isToogleSidebar,
  isEnabledUsers,
  operatingLocationList,
  merchantCategories,
  merchantSubCategories,
}) {
  const [orderStatus, setOrderStatus] = useState("ALL");
  const [opLocation, setOpLocation] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [searchKeyWord, setSearchKeyword] = useState("");

  // for showing details of users
  const [openEye, setOpenEye] = useState(false);
  const [userDetailsId, setUserDetailsId] = useState(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPosts, setCurrentPosts] = useState([]);

  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const dispatch = useDispatch();

  const getLocatDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };

  useEffect(() => {
    let searchKeyWord = "";
    dispatch({
      type: "FETCH_ORDER_LIST_BY_STATUS_REQUEST",
      payload: {
        orderStatus,
        currentPage,
        postsPerPage,
        searchKeyWord,
        opLocation,
      },
    });
  }, [orderStatus, currentPage, postsPerPage, opLocation]);
  useEffect(() => {
    let orderStatus = "All";
    setCurrentPage(1);
    dispatch({
      type: "SEARCH_ORDER_LIST_REQUEST",
      payload: {
        searchKeyWord,
        orderStatus,
        currentPage,
        postsPerPage,
        opLocation,
      },
    });
  }, [searchKeyWord]);
  // fetch operating location
  useEffect(() => {
    let postsPerPage = 50;
    let currentPage = 1;
    dispatch({
      type: "FETCH_OPERATING_LOCATION_REQUEST",
      payload: { postsPerPage, currentPage },
    });
    dispatch({
      type: "FETCH_MERCHANT_CATEGORY_REQUEST",
      payload: { postsPerPage, currentPage },
    });
  }, []);

  for (let i = 1; i <= orderList.totalPage; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    if (isEnabledUsers === "b") {
      setTimeout(() => {
        dispatch({
          type: "FETCH_ORDER_LIST_REQUEST",
          payload: { orderStatus, currentPage, postsPerPage, opLocation },
        });
      }, 1000);
    }
  }, [isEnabledUsers]);

  const str = () =>
    orderList.data !== undefined &&
    orderList.data.map((item) => (
      <tr key={item.paymentId}>
        <td>{item.customerOrderId}</td>
        <td>{item.isPhoneOrder ? "Phone Order" : "Normal Order"}</td>
        <td>{item.deliveryInfoDto.deliveryFullName}</td>
        <td>
          {item?.cartData?.deliveryInfoDto?.scheduledTime !== null
            ? getLocatDateTime(item?.cartData?.deliveryInfoDto?.scheduledTime)
            : "Not Scheduled"}
        </td>
        <td>
          {item.checkOutDateTime !== "null"
            ? getLocatDateTime(item.checkOutDateTime)
            : "Not Available"}
        </td>
        <td>
          {item.deliveryAssignedTo !== null
            ? `${item.deliveryAssignedTo}`
            : "Not Assigned"}
        </td>
        <td>$ {item.actualPaymentData.grandTotal}</td>
        <td>
          <span
            style={{
              backgroundColor: "purple",
              color: "white",
              padding: "3px 5px",
              borderRadius: "4px",
            }}
          >
            {item.paymentStatus}
          </span>
        </td>
        <td>
          <Tippy content="View" placement="bottom">
            <span>
              <BsEyeFill
                className="action--edit__admin"
                onClick={() => {
                  setUserDetailsId(item.customerOrderId);
                  setOpenEye(true);
                }}
              />{" "}
            </span>
          </Tippy>
        </td>
      </tr>
    ));

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Manage Merchant Order</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div
              className="user_groups--button_n_search--button"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div style={{ marginBottom: "0.6rem" }}>
                <label
                  htmlFor="pageSize"
                  style={{
                    marginLeft: "10px",
                    fontSize: "18px",
                    fontFamily: "bold",
                    color: "#3e445e",
                    marginRight: "-10px",
                  }}
                >
                  Per Page
                </label>
                <select
                  style={{ width: "84px" }}
                  name="pageSize"
                  onChange={(e) => setPostsPerPage(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              </div>
              <div style={{ marginBottom: "0.6rem" }}>
                <label
                  htmlFor=""
                  style={{
                    marginLeft: "10px",
                    fontSize: "18px",
                    fontFamily: "bold",
                    color: "#3e445e",
                    marginRight: "-10px",
                  }}
                >
                  Order Status
                </label>
                <select
                  name="status"
                  onChange={(e) => setOrderStatus(e.target.value)}
                  style={{ width: "84px" }}
                >
                  <option value="ALL">ALL</option>
                  <option value="ORDER_PLACED">ORDER_PLACED</option>
                  <option value="ORDER_CONFIRMED">ORDER_CONFIRMED</option>
                  <option value="PROCESSING">PROCESSING</option>
                  <option value="ORDER_READY">ORDER_READY</option>
                  <option value="DELIVERY_ON_THE_WAY">
                    DELIVERY_ON_THE_WAY
                  </option>
                  <option value="DELIVERY_REACHED_DESTINATION">
                    DELIVERY_REACHED_DESTINATION
                  </option>
                  <option value="DELIVERY_COMPLETED">
                    {" "}
                    DELIVERY_COMPLETED
                  </option>
                  <option value="REJECTED">REJECTED</option>
                  <option value="CANCELLED"> CANCELLED</option>
                </select>
              </div>
              <div style={{ marginBottom: "0.6rem" }}>
                <label
                  htmlFor=""
                  style={{
                    marginLeft: "10px",
                    fontSize: "18px",
                    fontFamily: "bold",
                    color: "#3e445e",
                    marginRight: "-10px",
                  }}
                >
                  Location
                </label>
                <select
                  name=""
                  id=""
                  onChange={(e) => setOpLocation(e.target.value)}
                  style={{ width: "84px" }}
                >
                  <option value="">All</option>
                  {operatingLocationList?.data?.map((item) => {
                    if (item.isActive) {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.categoryName}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
              <div style={{ marginBottom: "0.6rem" }}>
                <label
                  htmlFor=""
                  style={{
                    marginLeft: "10px",
                    fontSize: "18px",
                    fontFamily: "bold",
                    color: "#3e445e",
                    marginRight: "-10px",
                  }}
                >
                  Category
                </label>
                <select
                  name=""
                  id=""
                  onChange={(e) => {
                    setCategory(e.target.value);
                    if (
                      e.target.value !== null &&
                      e.target.value !== undefined &&
                      e.target.value !== ""
                    ) {
                      dispatch({
                        type: "FETCH_MERCHANT_SUB_CATEGORY_REQUEST",
                        payload: {
                          categoryId: e.target.value,
                          postsPerPage: 1,
                          currentPage: 10,
                        },
                      });
                    }
                  }}
                  style={{ width: "84px" }}
                >
                  <option value="">All</option>
                  {merchantCategories?.data?.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.locationName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div style={{ marginBottom: "0.6rem" }}>
                <label
                  htmlFor=""
                  style={{
                    marginLeft: "10px",
                    fontSize: "18px",
                    fontFamily: "bold",
                    color: "#3e445e",
                    marginRight: "-10px",
                  }}
                >
                  Sub-Category
                </label>
                <select
                  name=""
                  id=""
                  onChange={(e) => setSubCategory(e.target.value)}
                  style={{ width: "84px" }}
                >
                  <option value="">All</option>
                </select>
              </div>
            </div>
            <div className="user_groups--button_n_search--search">
              <form>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchKeyWord}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                />
              </form>
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="order--itemName">Order ID</th>
                  <th className="order--itemName">Order Type</th>

                  <th className="order--customerName">Customer Name</th>
                  <th className="order--sheduledTime">Sheduled Time</th>
                  <th className="order--status">Date Of Order</th>
                  <th className="order--status">Assigned To</th>
                  <th className="order--grandTotal">Grand Total</th>
                  <th className="order--status">Payment Status</th>
                  <th className="order--actions" style={{ width: "15vw" }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>{str()}</tbody>
            </table>
            <div
              className={`${openEye ? "mymodal modal_activated" : "mymodal"}`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {userDetailsId !== null && (
                  <OrderDetails id={userDetailsId} opLocation={opLocation} />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setOpenEye(!openEye);
                        setUserDetailsId(null);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > orderList.totalData
                  ? orderList.totalData
                  : indexOfLastPost}{" "}
                of {orderList.totalData} entries
              </p>
              <nav aria-label="...">
                <ul
                  className="pagination"
                  style={{ maxWidth: "400px", overflowX: "auto" }}
                >
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(MerchantOrderManagement);
