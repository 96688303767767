import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../../LoadingComponent";
import Footer from "../../Z_Footer/Footer";
// import AddServiceRate from './AddServiceRate'
import moment from "moment";
import { useParams } from "react-router";
import Tippy from "@tippyjs/react";
import { AiFillEye } from "react-icons/ai";
import Menu from "./Menu";
import { BsCheckCircle } from "react-icons/bs";
import AddCategory from "./AddCategory";
import UpdateCategory from "./UpdateCategory";

const mapStateToProps = (store) => {
  const {
    isLoadingOnLogin,
    categoryList,
    isToogleSidebar,
    isOperationSuccessful,
  } = store.mainState;
  return {
    isLoadingOnLogin,
    categoryList,
    isToogleSidebar,
    isOperationSuccessful,
  };
};

function MenuList({
  isLoadingOnLogin,
  categoryList,
  isToogleSidebar,
  isOperationSuccessful,
}) {
  const dispatch = useDispatch();
  const { resId } = useParams();
  const [selectedCateogory, setSelectedCategory] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [activateModal, setActivateModal] = useState("");
  const [deactivateModal, setDeactivateModal] = useState("");
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [activateConfirmationText, setactivateConfirmationText] = useState("");
  const [deactivateConfirmationText, setdeactivateConfirmationText] =
    useState("");
  const [edtingItem, setEditingItem] = useState(null);

  const [isAddCategoryOpen, setIsAddCategoryOpen] = useState(false);
  const [isEditCategoryOpen, setIsEditCategory] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPosts, setCurrentPosts] = useState([]);

  let pageNumbers = [];
  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  for (let i = 1; i <= categoryList.totalPage; i++) {
    pageNumbers.push(i);
  }
  console.log("pagenumbers", pageNumbers);
  console.log("pagenumbers", categoryList);
  useEffect(() => {
    dispatch({
      type: "FETCH_CATEGORY_LIST_REQUEST",
      payload: { postsPerPage, currentPage, resId: resId },
    });
  }, [postsPerPage, currentPage, isOperationSuccessful]);
  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };

  useEffect(() => {
    if (isOperationSuccessful) {
      setActivateModal(false);
      setDeactivateModal(false);
      setactivateConfirmationText("");
      setdeactivateConfirmationText("");
      setIsAddCategoryOpen(false);
      setIsEditCategory(false);
    }
  }, [isOperationSuccessful]);
  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Menu Category List</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <button onClick={() => setIsAddCategoryOpen(true)}>
                Add <i className="bi bi-plus c-plus" />
              </button>
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                Per Page
              </label>
              <select
                name="pageSize"
                onChange={(e) => setPostsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
            </div>
            <div className="user_groups--button_n_search--search">
              <input type="text" placeholder="Search" />
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="category--id">ID</th>

                  <th className="category--addedBy" style={{ width: "20vw" }}>
                    Category Name
                  </th>
                  <th className="category--addedBy" style={{ width: "18vw" }}>
                    Image
                  </th>
                  <th className="category--addedBy" style={{ width: "15vw" }}>
                    Description
                  </th>
                  <th className="securityQtn--state">Active</th>

                  <th className="category--addedBy">Actions</th>
                </tr>
              </thead>
              <tbody>
                {categoryList.data !== undefined &&
                  categoryList.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.categoryName}</td>
                      <td>
                        <img
                          style={{ height: "80px", width: "80px" }}
                          src={item.imageUrl}
                        />
                      </td>
                      <td>{item.description}</td>
                      <td>
                        {" "}
                        <span
                          style={{
                            backgroundColor: item.active ? "green" : "red",
                            padding: "2px 5px",
                            borderRadius: "4px",
                            color: "white",
                          }}
                        >
                          {item.active ? "Yes" : "No"}
                        </span>
                      </td>
                      <td>
                        {" "}
                        <Tippy
                          content={
                            <span>
                              {item.active ? "De-activate" : "Activate"}
                            </span>
                          }
                          placement="top"
                        >
                          <span>
                            <BsCheckCircle
                              className="action--eye__admin"
                              onClick={() => {
                                if (item.active) {
                                  setDeactivateModal(true);
                                } else {
                                  setActivateModal(true);
                                }
                                setEnableDisableItem(item);
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                        <Tippy content={"View Menu"} placement="top">
                          <span>
                            <AiFillEye
                              className="action--eye__admin"
                              onClick={() => {
                                setSelectedCategory(item.id);
                                setShowMenu(true);
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                        <Tippy content="Edit" placement="bottom">
                          <span>
                            <AiFillEdit
                              className="edit--icon"
                              onClick={() => {
                                setIsEditCategory(true);
                                setEditingItem("");
                                setEditingItem(item);
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div
              className={`${
                activateModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div
                  className="delete--admin--container__heading"
                  style={{ color: "black" }}
                >
                  Are you sure want to activate this Category ?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div className="reason--enableDisable">
                      <label>Confirmation Text : </label>
                      <textarea
                        type="text"
                        style={{
                          border: "1px solid #d7dbd8",
                          borderRadius: "5px",
                        }}
                        placeholder="Confirmation Text"
                        value={activateConfirmationText}
                        onChange={(e) =>
                          setactivateConfirmationText(e.target.value)
                        }
                      ></textarea>
                    </div>
                    {isLoadingOnLogin && <LoadingComponent />}
                  </div>
                  <div className="delete--admin--container__footer">
                    <button onClick={() => setActivateModal(!activateModal)}>
                      Cancel
                    </button>

                    <button
                      onClick={() => {
                        dispatch({
                          type: "ACTIVATE_MENU_CATEGORY_REQUEST",
                          payload: {
                            id: enableDisableItem.id,
                            values: {
                              confirmationText: activateConfirmationText,
                            },
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            <div
              className={`${
                deactivateModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div
                  className="delete--admin--container__heading"
                  style={{ color: "black" }}
                >
                  Are you sure want to deactivate this Category ?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div className="reason--enableDisable">
                      <label>Confirmation Text : </label>
                      <textarea
                        type="text"
                        style={{
                          border: "1px solid #d7dbd8",
                          borderRadius: "5px",
                        }}
                        placeholder="Confirmation Text"
                        value={deactivateConfirmationText}
                        onChange={(e) =>
                          setdeactivateConfirmationText(e.target.value)
                        }
                      ></textarea>
                    </div>
                    {isLoadingOnLogin && <LoadingComponent />}
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() => setDeactivateModal(!deactivateModal)}
                    >
                      Cancel
                    </button>

                    <button
                      onClick={() => {
                        dispatch({
                          type: "DEACTIVATE_MENU_CATEGORY_REQUEST",
                          payload: {
                            id: enableDisableItem.id,
                            values: {
                              confirmationText: deactivateConfirmationText,
                            },
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            <div
              className={`${showMenu ? "mymodal modal_activated" : "mymodal"}`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {selectedCateogory !== null && <Menu id={selectedCateogory} />}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setShowMenu(!showMenu);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                isAddCategoryOpen ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {<AddCategory restaurantId={resId} />}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setIsAddCategoryOpen(false);
                        dispatch({
                          type: "FETCH_CATEGORY_LIST_REQUEST",
                          payload: { postsPerPage, currentPage, resId: resId },
                        });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                isEditCategoryOpen ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {edtingItem !== null && (
                  <UpdateCategory item={edtingItem} restaurantId={resId} />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setEditingItem(" ");
                        setIsEditCategory(false);
                        dispatch({
                          type: "FETCH_CATEGORY_LIST_REQUEST",
                          payload: { postsPerPage, currentPage, resId: resId },
                        });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > categoryList.totalData
                  ? categoryList.totalData
                  : indexOfLastPost}{" "}
                of {categoryList.totalData} entries
              </p>
              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(MenuList);
