import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import { BsX } from "react-icons/bs";
import dayjs from "dayjs";

import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const mapStateToProps = (store) => {
  const {
    isToogleSidebar,
    isLoadingOnLogin,
    isEnabledUsers,
    walletData,
    transactionType,
  } = store.mainState;
  return {
    isToogleSidebar,
    isLoadingOnLogin,
    isEnabledUsers,
    walletData,
    transactionType,
  };
};

function Wallet({
  isToogleSidebar,
  isLoadingOnLogin,
  isEnabledUsers,
  walletData,
  transactionType,
}) {
  const [transactionId, setTransactionId] = useState(null);
  // for Enable/Disable adds
  const [showSmModal, setShowSmModal] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  for (let i = 1; i <= Math.ceil(walletData.totalData / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    if (transactionType === "unverified") {
      setCurrentPage(1);
      dispatch({
        type: "SEARCH_WALLET_DATA_REQUEST",
        payload: {
          currentPage,
          postsPerPage,
          searchParam,
        },
      });
    } else {
      setCurrentPage(1);
      dispatch({
        type: "SEARCH_VERIFIED_WALLET_DATA_REQUEST",
        payload: {
          currentPage,
          postsPerPage,
          searchParam,
        },
      });
    }
  }, [searchParam]);
  useEffect(() => {
    dispatch({
      type: "FETCH_WALLET_DATA_REQUEST",
      payload: {
        currentPage,
        postsPerPage,
      },
    });
  }, [currentPage, postsPerPage]);

  useEffect(() => {
    if (isEnabledUsers === "b") {
      let currentPage = 1;
      let postsPerPage = 10;
      setTimeout(() => {
        setShowSmModal(false);
        dispatch({
          type: "FETCH_WALLET_DATA_REQUEST",
          payload: {
            currentPage,
            postsPerPage,
          },
        });
      }, 1000);
    }
  }, [isEnabledUsers]);
  console.log("the transaction Type", transactionType);
  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Wallet</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                Per Page
              </label>
              <select
                name="pageSize"
                onChange={(e) => setPostsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <div className="wallet--button">
                <p
                  className={`${
                    transactionType === "unverified" && "my-bgColor"
                  }`}
                  onClick={() => {
                    let currentPage = 1;
                    let postsPerPage = 10;
                    let searchParam = "";
                    dispatch({
                      type: "FETCH_WALLET_DATA_REQUEST",
                      payload: {
                        currentPage,
                        postsPerPage,
                      },
                    });
                  }}
                >
                  Not Verified
                </p>
                <p
                  className={`${
                    transactionType === "verified" && "my-bgColor"
                  }`}
                  onClick={() => {
                    let currentPage = 1;
                    let postsPerPage = 10;
                    let searchParam = "";
                    dispatch({
                      type: "FETCH_VERIFIED_WALLET_DATA_REQUEST",
                      payload: {
                        currentPage,
                        postsPerPage,
                      },
                    });
                  }}
                >
                  Verified
                </p>
              </div>
            </div>
            <div className="user_groups--button_n_search--search">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchParam(e.target.value)}
              />
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            {transactionType === "unverified" ? (
              <table className="table_x">
                <thead>
                  <tr>
                    <th className="wallet--id">Transaction ID</th>
                    <th className="wallet--date">Date & Time</th>
                    <th className="wallet--remark">Transaction Remarks</th>
                    <th className="wallet--fromWallet">From Wallet</th>
                    <th className="wallet--balance">
                      Usable/With Held/Available Balance
                    </th>
                    <th className="wallet--balance">Withdraw By</th>
                    <th className="wallet--method">Trasaction Amount($)</th>
                    <th className="wallet--actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {walletData.data?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.transactionId}</td>

                      <td>
                        {dayjs(item.transactionDateTime).format("YYYY-MMM-DD")}
                      </td>
                      <td>{item.transactionRemarks}</td>
                      <td>{item.fromWallet}</td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td>UB</td>
                              <td>{item.usableFromBalance.toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td>WHB</td>
                              <td>{item.withHeldFromBalance.toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td>AB</td>
                              <td>{item.availableFromBalance.toFixed(2)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        {item.withdrawalFullName}({item.driverUserName})(
                        {item.driverContactNumber})
                      </td>
                      {/* <td>{item.usableFromBalance.toFixed(2)}</td>
                    <td>{item.withHeldFromBalance.toFixed(2)}</td>
                    <td>{item.availableFromBalance.toFixed(2)}</td> */}
                      <td> $ {item.transactionAmount}</td>
                      <td>
                        <Tippy content="Verify" placement="bottom">
                          <span>
                            <BsCheckCircle
                              className="edit--icon"
                              onClick={() => {
                                setShowSmModal(!showSmModal);
                                setTransactionId(item.transactionId);
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table className="table_x">
                <thead>
                  <tr>
                    <th className="wallet--id">Transaction ID</th>
                    <th className="wallet--date">Date & Time</th>
                    <th className="wallet--remark">Transaction Remarks</th>
                    <th className="wallet--fromWallet">From Wallet</th>
                    <th className="wallet--balance">
                      Usable/With Held/Available Balance
                    </th>
                    <th className="wallet--fromWallet">Transaction Amount</th>
                    <th className="wallet--balance">Withdraw By</th>
                  </tr>
                </thead>
                <tbody>
                  {walletData.data?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.transactionId}</td>
                      <td>
                        {dayjs(item.transactionDateTime).format("YYYY-MMM-DD")}
                      </td>
                      <td>{item.transactionRemarks}</td>
                      <td>{item.fromWallet}</td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td>UB</td>
                              <td>{item.usableFromBalance.toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td>WHB</td>
                              <td>{item.withHeldFromBalance.toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td>AB</td>
                              <td>{item.availableFromBalance.toFixed(2)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>$ {item.transactionAmount}</td>
                      <td>
                        {item.withdrawalFullName}({item.driverUserName})(
                        {item.driverContactNumber})
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {/* for verifying trasaction */}

            <div
              className={`${
                showSmModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading"></div>
                <>
                  <div className="delete--admin--container__body">
                    <h1>Are you sure to verify this trasaction ?</h1>
                    <div>
                      {isEnabledUsers === "a" && (
                        <div className="deleting--something">
                          {" "}
                          <p>Processing...</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "b" && (
                        <div className="deleting--something">
                          {" "}
                          <TiTick className="success--icon" /> <p>Successful</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button onClick={() => setShowSmModal(!showSmModal)}>
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        dispatch({
                          type: "VERIFY_TRANSACTION_REQUEST",
                          payload: {
                            transactionId,
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > walletData.totalData
                  ? walletData.data?.length
                  : indexOfLastPost}{" "}
                of {walletData.totalData} entries
              </p>
              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(Wallet);
