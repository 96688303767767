import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";

import "react-datepicker/dist/react-datepicker.css";
import { LatLangGenerator } from "./LatLangGenerator";
import dayjs from "dayjs";
import moment from "moment";

const mapStateToProps = (store) => {
  const { buttonText, isEnabledUsers, searchedRestaurantName } =
    store.mainState;
  return { buttonText, isEnabledUsers, searchedRestaurantName };
};

function UpdateCoupon({
  buttonText,
  isEnabledUsers,
  item,
  searchedRestaurantName,
}) {
  const [couponName, setCouponName] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [details, setDetails] = useState("");
  const [couponCodeType, setCouponCodeType] = useState("");
  const [isMinimumValue, setIsMinimumValue] = useState(false);
  const [isAreaApplicable, setIsAreaApplicable] = useState(false);
  const [isMultipleUsage, setIsMultipleUsage] = useState(false);
  const [discountType, setDiscountType] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [applyDiscountOn, setApplyDiscountOn] = useState("");
  const [myValidStartDate, setValidStartDate] = useState("");
  const [validStartTime, setValidStartTime] = useState("");
  const [myExpireDate, setMyExpireDate] = useState("");
  const [expireTime, setExpireTime] = useState("");
  const [isSheduled, setIsSheduled] = useState(false);
  const [notificationFlag, setNotificationFlag] = useState(false);
  const [sheduledDate, setSheduledDate] = useState(null);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [couponApplyFor, setCouponApplyFor] = useState("");
  const [myMinimumOrderValue, setMyMinimunOrderValue] = useState();
  const [myRadius, setMyRadius] = useState();
  const [frequency, setFrequency] = useState();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [location, setLocation] = useState("");
  const [restaurantId, setRestaurantId] = useState(null);
  const [resturantName, setRestaurantName] = useState(null);

  const getLocatDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };

  // const [setDupStart]
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const finalValidStartDate = [
    convert(myValidStartDate),
    " ",
    validStartTime,
    ":00",
  ].join("");
  const finalExpireDate = [convert(myExpireDate), " ", expireTime, ":00"].join(
    ""
  );
  const finalSheduledDate = [
    convert(sheduledDate),
    " ",
    scheduledTime,
    ":00",
  ].join("");
  const xx = new Date(finalValidStartDate).toUTCString().split(" ")[4];
  const finalXX = [convert(myValidStartDate), " ", xx].join("");
  const yy = new Date(finalExpireDate).toUTCString().split(" ")[4];
  const finalYY = [convert(finalExpireDate), " ", yy].join("");
  const zz = new Date(finalSheduledDate).toUTCString().split(" ")[4];
  const finalZZ = [convert(finalSheduledDate), " ", zz].join("");

  const myDiscountType = ["FLAT", "PERCENTAGE"];

  useEffect(() => {
    if (resturantName !== "SEARCH_RESTAURANT_REQUEST") {
      dispatch({
        type: "SEARCH_RESTAURANT_REQUEST",
        payload: { resturantName },
      });
    }
  }, [resturantName]);

  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: "UPDATE_COUPON_REQUEST",
      payload: {
        couponCodeId: item.id,
        couponCode: couponCode,
        codeName: couponName,
        codeDetails: details,
        discountType: discountType,
        discountAmount: discountAmount,
        expiryDate: finalYY === "NaN-aN-aN " ? item.expiryDate : finalYY,
        validStartDate:
          finalXX[12] === undefined ? item.validStartDate : finalXX,
        isScheduled: isSheduled,
        scheduledDateTime: finalZZ,
        notificationFlag: notificationFlag,
        couponCodeType: couponCodeType,
        isMinimumValue: isMinimumValue,
        minValue: myMinimumOrderValue,
        isMultipleUsage: isMultipleUsage,
        multipleUsageCount: frequency,
        isAreaApplicable: isAreaApplicable,
        longitude: latitude,
        latitude: longitude,
        radius: myRadius,
        location: location,
        applyDiscountOn: applyDiscountOn,
        applicableFor: couponApplyFor,
        applicableRestaurantId: restaurantId,
      },
    });
  };
  useEffect(() => {
    setCouponName(item.codeName);
    setCouponCode(item.couponCode);
    setDetails(item.details);
    setCouponCodeType(item.couponCodeType);
    setIsMinimumValue(item.isMinimumValue);
    setIsAreaApplicable(item.isAreaApplicable);
    setIsMultipleUsage(item.isMultipleUsage);
    setDiscountType(item.discountType);
    setDiscountAmount(item.discountAmount);
    setApplyDiscountOn(item.applyDiscountOn);
    setValidStartDate(new Date(getLocatDateTime(item.validStartDate)));
    setValidStartTime(new Date(getLocatDateTime(item.validStartDate)));
    setMyExpireDate(new Date(getLocatDateTime(item.expiryDate)));
    setExpireTime(new Date(getLocatDateTime(item.expiryDate)));
    setIsSheduled(item.isScheduled);
    setNotificationFlag(item.notificationFlag);
    setSheduledDate(new Date(item.scheduledDateTime));
    setScheduledTime(new Date(item.scheduledDateTime));
    setCouponApplyFor(item.applicableFor);
    setMyMinimunOrderValue(item.minimumValue);
    setMyRadius(item.radius);
    setFrequency(item.multipleUsageCount);
    setLatitude(item.latitude);
    setLongitude(item.longitude);
    setLocation("");
    setRestaurantId(item.restaurantId);
    setRestaurantName(item.restaurantName);
  }, [item]);
  const handlelatLong = (myvalue) => {
    setLatitude(myvalue.lat);
    setLongitude(myvalue.lng);
  };
  const handleAddress = (address) => {
    setLocation(address);
  };
  console.log("my minimun order value", myMinimumOrderValue);
  return (
    <div>
      <div className="Modal--heading">Update Coupon</div>
      <div className="CouponCode--Modal--form">
        <form>
          <div className="CouponCode--Modal--form--title">
            <p>
              <label htmlFor="title">Name: </label>
              <input
                type="text"
                value={couponName}
                placeholder="Coupon Name"
                onChange={(e) => setCouponName(e.target.value)}
              />
            </p>
          </div>
          <div className="CouponCode--Modal--form--title">
            <p>
              <label htmlFor="title">Code: </label>
              <input
                type="text"
                value={couponCode}
                placeholder="Coupon code"
                onChange={(e) => setCouponCode(e.target.value)}
              />
            </p>
          </div>
          <div className="CouponCode--Modal--form--title">
            <p>
              <label htmlFor="title">Desc: </label>
              <textarea
                value={details}
                placeholder="Description about CouponCode"
                onChange={(e) => setDetails(e.target.value)}
              ></textarea>
            </p>
          </div>
          <hr />
          <p className="radio--button__title">Coupon Type : </p>
          <div className="CouponCode--Modal--form--radios">
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="couponcodeType"
                value="CUSTOMER"
                checked={couponCodeType == "CUSTOMER" ? true : false}
                onChange={(e) => setCouponCodeType(e.target.value)}
              />
              <label htmlFor="title">Customer</label>
            </p>
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="couponcodeType"
                value="RESTAURANT"
                checked={couponCodeType === "RESTAURANT" ? true : false}
                onChange={(e) => setCouponCodeType(e.target.value)}
              />
              <label htmlFor="title">Restaurant</label>
            </p>
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="couponcodeType"
                value="DRIVER"
                checked={couponCodeType == "DRIVER" ? true : false}
                onChange={(e) => setCouponCodeType(e.target.value)}
              />
              <label htmlFor="title">Driver</label>
            </p>
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="couponcodeType"
                value="FIRST_TIME_REGISTERED_CUSTOMER"
                onChange={(e) => setCouponCodeType(e.target.value)}
              />
              <label htmlFor="title">First Time Registered Customer</label>
            </p>
          </div>
          <hr />

          <p className="radio--button__title">Coupon Apply For : </p>
          <div className="CouponCode--Modal--form--radios">
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                value="All"
                checked={couponApplyFor !== "RESTAURANT"}
                name="CouponApplFor"
                onChange={(e) => setCouponApplyFor(e.target.value)}
              />
              <label htmlFor="title">All</label>
            </p>
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="CouponApplFor"
                checked={couponApplyFor === "RESTAURANT"}
                value="RESTAURANT"
                onChange={(e) => setCouponApplyFor(e.target.value)}
              />
              <label htmlFor="title">Individual Restaurant / Merchants</label>
            </p>
            {couponApplyFor === "RESTAURANT" && (
              <p>
                <label htmlFor="title">a</label>
                <input
                  type="text"
                  placeholder={item.restaurantName}
                  value={resturantName}
                  onChange={(e) => setRestaurantName(e.target.value)}
                />{" "}
                : <label htmlFor="title">Restaurant Name</label>
                {resturantName !== null && (
                  <div className="restaunatSearched--name">
                    <div className="restaunatSearched--name__result">
                      {searchedRestaurantName.data.map((item) => (
                        <p
                          className="mySearched--item"
                          onClick={() => {
                            setRestaurantId(item.id);
                            setRestaurantName(item.restaurantName);
                          }}
                        >
                          {item.restaurantName}
                        </p>
                      ))}
                    </div>
                  </div>
                )}
              </p>
            )}
          </div>
          <hr />
          <p className="radio--button__title">Minimum Order Value : </p>
          <div className="CouponCode--Modal--form--radios">
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="minimumOrderValue"
                value={isMinimumValue}
                checked={!isMinimumValue}
                onChange={(e) => setIsMinimumValue(!isMinimumValue)}
              />
              <label htmlFor="title">No Limit</label>
            </p>
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="minimumOrderValue"
                value={isMinimumValue}
                checked={isMinimumValue}
                onChange={(e) => setIsMinimumValue(!isMinimumValue)}
              />
              <label htmlFor="title">Minimun of $</label>
            </p>
            {isMinimumValue && (
              <p>
                <label htmlFor="title">a</label>
                <input
                  type="text"
                  placeholder="Price"
                  value={myMinimumOrderValue}
                  onChange={(e) => setIsMinimumValue(myMinimumOrderValue)}
                />
                : <label htmlFor="title">Price</label>
              </p>
            )}
          </div>
          <hr />
          <p className="radio--button__title">Area : </p>
          <div className="CouponCode--Modal--form--radios">
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="area"
                value={isAreaApplicable}
                checked={!isAreaApplicable}
                onChange={(e) => setIsAreaApplicable(!isAreaApplicable)}
              />
              <label htmlFor="title">Any</label>
            </p>
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="area"
                value={isAreaApplicable}
                checked={isAreaApplicable}
                onChange={(e) => setIsAreaApplicable(!isAreaApplicable)}
              />
              <label htmlFor="title">Select Your Area</label>
            </p>
            {/* {areaForCoupon === "selectArea" && <p><label htmlFor="title">a</label>
                            <input type="text" placeholder="Location" /> <label htmlFor="title"> + <input type="number" value={myRadius} onChange={(e) => setMyRadius(e.target.value)} placeholder="distance" /> KM</label>
                        </p>} */}
            {isAreaApplicable && (
              <p style={{ marginLeft: "20px", display: "flex" }}>
                <label htmlFor="title">a</label>
                <LatLangGenerator
                  address={item.address || "Enter Your Address"}
                  handlelatLong={handlelatLong}
                  handleAddress={handleAddress}
                />{" "}
                <p>
                  <input
                    type="number"
                    value={myRadius}
                    onChange={(e) => setMyRadius(e.target.value)}
                    placeholder="KM"
                  />{" "}
                  : Radius
                </p>
              </p>
            )}
          </div>
          <hr />
          <p className="radio--button__title">Coupon Code Use For : </p>
          <div className="CouponCode--Modal--form--radios">
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="couponCodeUseFor"
                value={isMultipleUsage}
                value="not_multipletime"
                checked={!isMultipleUsage}
                onChange={(e) => setIsMultipleUsage(!isMultipleUsage)}
              />
              <label htmlFor="title">One Time</label>
            </p>
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="couponCodeUseFor"
                value={isMultipleUsage}
                checked={isMultipleUsage}
                onChange={(e) => setIsMultipleUsage(!isMultipleUsage)}
              />
              <label htmlFor="title">Multiple Time</label>
            </p>
            {isMultipleUsage && (
              <p>
                <label htmlFor="title">a</label>
                <input
                  type="text"
                  placeholder="Frequency"
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}
                />{" "}
                <label htmlFor="title"> : Frequency</label>
              </p>
            )}
          </div>
          <hr />
          <p className="radio--button__title">Discount Type : </p>
          <div className="CouponCode--Modal--form--selectors">
            <p>
              <label htmlFor="title">a</label>{" "}
              <select onChange={(e) => setDiscountType(e.target.value)}>
                <option value={discountType}>{discountType}</option>
                {discountType === "FLAT" ? (
                  <option value="PERCENTAGE"> PERCENTAGE</option>
                ) : (
                  <option value="FLAT">FLAT</option>
                )}
              </select>{" "}
              <label style={{ marginLeft: "10px" }}>Amount : </label>{" "}
              <input
                type="number"
                placeholder="Amount"
                value={discountAmount}
                onChange={(e) => setDiscountAmount(e.target.value)}
              />
            </p>
          </div>
          <p className="radio--button__title">Apply Discount On : </p>
          <div className="CouponCode--Modal--form--radios">
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="applyDiscountOn"
                value="PRODUCT"
                checked={applyDiscountOn === "PRODUCT"}
                onChange={(e) => setApplyDiscountOn(e.target.value)}
              />
              <label htmlFor="title">Product</label>
            </p>
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="applyDiscountOn"
                value="SERVICE_FEE"
                checked={applyDiscountOn === "SERVICE_FEE"}
                onChange={(e) => setApplyDiscountOn(e.target.value)}
              />
              <label htmlFor="title">Service Fee</label>
            </p>
            <p>
              <label htmlFor="title">a</label>
              <input
                type="radio"
                name="applyDiscountOn"
                value="DELIVERY_FEE"
                checked={applyDiscountOn === "DELIVERY_FEE"}
                onChange={(e) => setApplyDiscountOn(e.target.value)}
              />
              <label htmlFor="title">Delivery Fee</label>
            </p>
          </div>
          <hr />
          <hr />
          <p className="radio--button__title">Effective From : </p>
          <div className="CouponCode--Modal--form--startDate">
            <div className="CouponCode--Modal--form--startDate__date">
              <p>
                <label htmlFor="title">Start Date : </label>
                <DatePicker
                  placeholderText="Select Date"
                  minDate={new Date()}
                  selected={myValidStartDate}
                  onChange={(date) => setValidStartDate(date)}
                />
              </p>
            </div>
            <div className="CouponCode--Modal--form--startDate__time">
              <p>
                <label htmlFor="title">Time : </label>
                <TimePicker
                  format="hh:mm a"
                  onChange={setValidStartTime}
                  value={validStartTime}
                />
              </p>
            </div>
          </div>
          <div className="CouponCode--Modal--form--startDate">
            <div className="CouponCode--Modal--form--startDate__date">
              <p>
                <label htmlFor="title">Expire Date : </label>
                <DatePicker
                  placeholderText="Select Date"
                  minDate={new Date()}
                  selected={myExpireDate}
                  onChange={(date) => setMyExpireDate(date)}
                />
              </p>
            </div>
            <div className="CouponCode--Modal--form--startDate__time">
              <p>
                <label htmlFor="title">Time : </label>
                <TimePicker
                  format="hh:mm a"
                  onChange={setExpireTime}
                  value={expireTime}
                />
              </p>
            </div>
          </div>
          <hr />
          <p className="radio--button__title">Schedule Date And Time : </p>
          <div className="CouponCode--Modal--form--scheduled">
            <p>
              <label htmlFor="title">Notification Flag : </label>
              <input
                type="checkbox"
                value={notificationFlag}
                checked={notificationFlag}
                onChange={(e) => setNotificationFlag(e.target.checked)}
              />
            </p>
            <p style={{ marginLeft: "20%" }}>
              <label htmlFor="title">Is Scheduled : </label>
              <input
                type="checkbox"
                value={isSheduled}
                checked={isSheduled}
                onChange={(e) => setIsSheduled(e.target.checked)}
              />
            </p>
          </div>
          {isSheduled && (
            <div className="CouponCode--Modal--form--startDate">
              <div className="CouponCode--Modal--form--startDate__date">
                <p>
                  <label htmlFor="title">Date : </label>
                  <DatePicker
                    placeholderText="Select Date"
                    minDate={new Date()}
                    selected={sheduledDate}
                    onChange={(date) => setSheduledDate(date)}
                  />
                </p>
              </div>
              <div className="CouponCode--Modal--form--startDate__time">
                <p>
                  <label htmlFor="title">Time : </label>
                  <TimePicker
                    format="hh:mm a"
                    onChange={setScheduledTime}
                    placeholderText="Select Time"
                    value={scheduledTime}
                    className="myTime--picker"
                  />
                </p>
              </div>
            </div>
          )}

          <hr />
          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(UpdateCoupon);
