import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import { BsEyeFill, BsCheckCircle } from "react-icons/bs";
import { BsX } from "react-icons/bs";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "antd/dist/antd.css";
import moment from "moment";
import { Collapse } from "antd";
import CampaingHeading from "./CampaingHeading";
import EditCampaign from "./EditCampaign";

const { Panel } = Collapse;

const mapStateToProps = (store) => {
  const {
    isLoadingOnLogin,
    couponData,
    campaignList,
    isToogleSidebar,
    isEnabledUsers,
  } = store.mainState;
  return {
    isLoadingOnLogin,
    couponData,
    campaignList,
    isToogleSidebar,
    isEnabledUsers,
  };
};

function Campaign({
  isLoadingOnLogin,
  campaignList,
  couponData,
  isToogleSidebar,
  isEnabledUsers,
}) {
  const [showEnableItemModal, setShowEnableModal] = useState(false);
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [showItemEnableModal, setItemEnableModal] = useState(false);
  const [itemEnableDisableCamapain, setItemEnableDisableCampaign] = useState(
    {}
  );
  const [editingItem, setEditingItem] = useState(null);
  const [showEditCampaignModal, setShowEditCampaignModal] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [keyWord, setMyKeyword] = useState("");

  // Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [sortingParam, setSortingParam] = useState("id");

  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "FETCH_CAMPAIGN_LIST_REQUEST",
      payload: { currentPage, postsPerPage, keyWord: keyWord },
    });
  }, [currentPage, postsPerPage, keyWord]);

  for (let i = 1; i <= campaignList.totalPage; i++) {
    pageNumbers.push(i);
  }

  const getLocatDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };

  useEffect(() => {
    if (isEnabledUsers === "b") {
      dispatch({
        type: "FETCH_CAMPAIGN_LIST_REQUEST",
        payload: { currentPage, postsPerPage },
      });
      setItemEnableModal(false);
      setShowEnableModal(false);
    }
  }, [isEnabledUsers]);

  // console.log("the editing item",editingItem)
  const campaignTable = () => (
    <Collapse bordered={false} defaultActiveKey={["1"]}>
      {campaignList.data !== undefined &&
        campaignList.data.map((item, index) => (
          <Panel
            key={index + 1}
            header={
              <CampaingHeading
                item={item}
                setShowEditCampaignModal={setShowEditCampaignModal}
                setEditingItem={setEditingItem}
                setShowEnableModal={setShowEnableModal}
                setEnableDisableItem={setEnableDisableItem}
              />
            }
          >
            <div>
              <table style={{ marginLeft: "5vw" }}>
                <thead>
                  <tr style={{ backgroundColor: "pink" }}>
                    <th className="campaignItem--image">Image</th>
                    <th className="campaignItem--name">Item Name</th>
                    <th className="campaignItem--desc">Description</th>
                    <th className="campaignItem--quantity">Quantity</th>
                    {/* <th>Price</th> */}
                    <th className="campaignItem--status">Status</th>
                    <th className="campaignItem--actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {item.itemData.length > 0 &&
                    item.itemData.map((item) => (
                      <tr>
                        <td>
                          <img
                            src={item.itemImage}
                            style={{ height: "60px", width: "80px" }}
                            alt=""
                          />
                        </td>
                        <td>{item.itemName}</td>
                        <td style={{ width: "30vw" }}>{item.description}</td>
                        <td>{item.quantity}</td>
                        {/* <td>{item.price}</td> */}
                        <td>{item.isActive ? "Active" : "Not Active"}</td>
                        <td>
                          <Tippy
                            content={`${
                              item.isActive ? "De-activate" : "Activate"
                            }`}
                            placement="top"
                          >
                            <span>
                              <BsCheckCircle
                                className="edit--icon"
                                onClick={() => {
                                  setItemEnableDisableCampaign(item);
                                  setItemEnableModal(true);
                                }}
                              />{" "}
                            </span>
                          </Tippy>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Panel>
        ))}
    </Collapse>
  );

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Manage Campaign</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Campaign</h4>
              <button onClick={() => dispatch({ type: "ADD_CAMPAIGN_MODAL" })}>
                Add <i className="bi bi-plus c-plus" />
              </button>
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                Per Page
              </label>
              <select
                name="pageSize"
                onChange={(e) => setPostsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <div className="user_groups--button_n_search--search">
              <form>
                <input
                  value={keyWord}
                  onChange={(e) => setMyKeyword(e.target.value)}
                  type="text"
                  placeholder="Search"
                />
              </form>
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            {campaignTable()}

            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > campaignList.totalData
                  ? campaignList.totalData
                  : indexOfLastPost}{" "}
                of {campaignList.totalData} entries
              </p>
              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {/* edit operating location modal */}
        <div
          className={`${
            showEditCampaignModal ? "mymodal modal_activated" : "mymodal"
          }`}
        >
          <div
            className={`${
              !isToogleSidebar
                ? "manage_modal--container "
                : "manage_modal--container sidebarOpend_F_modal"
            }`}
          >
            {editingItem && <EditCampaign item={editingItem} />}
          </div>
          <div
            className={`${
              !isToogleSidebar
                ? "task--rectangle"
                : "task--rectangle withsidebarOpend"
            }`}
          >
            <div className="task--rectangle__circle">
              <div className="circle">
                {" "}
                <span
                  onClick={() => {
                    setShowEditCampaignModal(false);
                  }}
                >
                  <BsX />
                </span>
                <p>Edit</p>
              </div>
            </div>
          </div>
        </div>
        {/* enable disable campaign */}
        <form>
          <div
            className={`${
              showEnableItemModal ? "delete--admin" : "delete--admin__hidden"
            }`}
          >
            <div className="delete--admin--container">
              <div className="delete--admin--container__heading">
                {enableDisableItem.isActive ? (
                  <h1>Are you sure to Disable this ??</h1>
                ) : (
                  <h1>Are you sure to Enable this ??</h1>
                )}
              </div>
              <>
                <div className="delete--admin--container__body">
                  <div>
                    {isEnabledUsers === "a" && (
                      <div className="deleting--something">
                        {" "}
                        <p>Processing...</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "b" && (
                      <div className="deleting--something">
                        {" "}
                        <TiTick className="success--icon" /> <p>Successful</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "c" && (
                      <div className="deleting--something">
                        {" "}
                        <GiSkullCrossedBones
                          className="success--icon"
                          style={{ fontSize: "30px" }}
                        />{" "}
                        <p>Try Again</p>
                      </div>
                    )}
                  </div>
                  <div className="reason--enableDisable">
                    <label>Confirmation Text : </label>
                    <textarea
                      type="text"
                      placeholder="Confirmation Text"
                      value={confirmText}
                      onChange={(e) => setConfirmText(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="delete--admin--container__footer">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowEnableModal(!showEnableItemModal);
                    }}
                  >
                    Cancel
                  </button>
                  {enableDisableItem.isActive ? (
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        let enableDisableId = enableDisableItem.id;
                        dispatch({
                          type: "DISABLE_CAMPAIGN_REQUEST",
                          payload: {
                            id: enableDisableId,
                            confirmText: confirmText,
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  ) : (
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        let enableDisableId = enableDisableItem.id;
                        dispatch({
                          type: "ENABLE_CAMPAIGN_REQUEST",
                          payload: {
                            id: enableDisableId,
                            confirmText: confirmText,
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  )}
                </div>
              </>
            </div>
          </div>
        </form>
        {/* enable disable campaign item */}
        <form>
          <div
            className={`${
              showItemEnableModal ? "delete--admin" : "delete--admin__hidden"
            }`}
          >
            <div className="delete--admin--container">
              <div className="delete--admin--container__heading">
                {itemEnableDisableCamapain.isActive ? (
                  <h1>Are you sure to Disable this ??</h1>
                ) : (
                  <h1>Are you sure to Enable this ??</h1>
                )}
              </div>
              <>
                <div className="delete--admin--container__body">
                  <div>
                    {isEnabledUsers === "a" && (
                      <div className="deleting--something">
                        {" "}
                        <p>Processing...</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "b" && (
                      <div className="deleting--something">
                        {" "}
                        <TiTick className="success--icon" /> <p>Successful</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {" "}
                    {isEnabledUsers === "c" && (
                      <div className="deleting--something">
                        {" "}
                        <GiSkullCrossedBones
                          className="success--icon"
                          style={{ fontSize: "30px" }}
                        />{" "}
                        <p>Try Again</p>
                      </div>
                    )}
                  </div>
                  <div className="reason--enableDisable">
                    <label>Confirmation Text : </label>
                    <textarea
                      type="text"
                      placeholder="Confirmation Text"
                      value={confirmText}
                      onChange={(e) => setConfirmText(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="delete--admin--container__footer">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setItemEnableModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  {itemEnableDisableCamapain.isActive ? (
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        let enableDisableId = itemEnableDisableCamapain.id;
                        dispatch({
                          type: "DISABLE_CAMPAIGN_ITEM_REQUEST",
                          payload: {
                            id: enableDisableId,
                            confirmText: confirmText,
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  ) : (
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        let enableDisableId = itemEnableDisableCamapain.id;
                        dispatch({
                          type: "ENABLE_CAMPAIGN_ITEM_REQUEST",
                          payload: {
                            id: enableDisableId,
                            confirmText: confirmText,
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  )}
                </div>
              </>
            </div>
          </div>
        </form>

        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(Campaign);
