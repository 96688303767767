import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { AiFillEdit } from "react-icons/ai";
import { MdPhoneForwarded } from "react-icons/md";
import {
  BsCheckCircle,
  BsReverseLayoutTextSidebarReverse,
} from "react-icons/bs";
import { useHistory } from "react-router";

function CampaingHeading({
  item,
  setShowEditCampaignModal,
  setEditingItem,
  setShowEnableModal,
  setEnableDisableItem,
}) {
  const history = useHistory();
  return (
    <div style={{ display: "flex" }}>
      <p className="campaing--heading--img">
        <img
          style={{ height: "60px", width: "60px" }}
          src={item.campaignImage}
          alt=""
        />
      </p>
      <p className="campaing--heading--name">{item.campaignName}</p>
      {/* <p>{item.description}</p> */}
      <p className="campaing--heading--price">$ {item.price}</p>
      <p className="campaing--heading--status">
        {item.isActive ? (
          <span
            style={{
              backgroundColor: "green",
              padding: "4px",
              borderRadius: "4px",
              color: "white",
            }}
          >
            Active
          </span>
        ) : (
          <span
            style={{
              backgroundColor: "red",
              padding: "4px",
              borderRadius: "4px",
              color: "white",
            }}
          >
            Disabled
          </span>
        )}
      </p>
      <p className="campaing--heading--actions">
        <Tippy content="Edit" placement="top">
          <span>
            <AiFillEdit
              className="edit--icon"
              onClick={() => {
                setEditingItem(item);
                setShowEditCampaignModal(true);
              }}
            />{" "}
          </span>
        </Tippy>
        <Tippy
          content={
            <span>{`${item.isActive ? "De-activate" : "Activate"}`}</span>
          }
        >
          <span>
            <BsCheckCircle
              className="action--eye__admin"
              onClick={() => {
                setShowEnableModal(true);
                setEnableDisableItem(item);
              }}
            />
          </span>
        </Tippy>
        <Tippy content={<span>{"Order List"}</span>}>
          <span>
            <BsReverseLayoutTextSidebarReverse
              className="action--eye__admin"
              onClick={() =>
                history.push(`/manage-campaign-order-by-id/${item.id}`)
              }
            />
          </span>
        </Tippy>
        <Tippy content={<span>{"Phone Order"}</span>}>
          <span>
            <MdPhoneForwarded
              className="action--eye__admin"
              onClick={() => history.push(`/campaign-phone-order/${item.id}`)}
            />
          </span>
        </Tippy>
      </p>
    </div>
  );
}

export default CampaingHeading;
