import React, { useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsX, BsCheckCircle, BsEyeFill } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Layout from "../../../Layout/Layout";
import LoadingComponent from "../../LoadingComponent";
import Footer from "../../Z_Footer/Footer";
import AddMerchantCategory from "./AddMerchantCategory";
import { CgMenuRound } from "react-icons/cg";

function MerchantCategoryList() {
  const {
    isEnabledUsers,
    isLoadingOnLogin,
    isToogleSidebar,
    allMerchantCategories,
  } = useSelector((state) => state.mainState);

  const [showOptionModal, setShowOptionModal] = useState(false);
  const [showAssignDriverModal, setShowAssignDriverModal] = useState(false);
  const [openEye, setOpenEye] = useState(false);
  const [detailsShoingItem, setDetailsShhowingItem] = useState(null);

  const [showSmModal, setShowSmModal] = useState(false);
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [assigningBatchId, setAssigningBatchId] = useState(null);
  const [isAddCategoryModalVisible, setIsAddCategoryModalVisible] =
    useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPosts, setCurrentPosts] = useState([]);

  let pageNumbers = [];

  console.log(
    "the allMerchantCategories in the cmponent",
    allMerchantCategories
  );

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  for (let i = 1; i <= allMerchantCategories.totalPage; i++) {
    pageNumbers.push(i);
  }

  const [rate, setRate] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_ALL_MERCHANT_CATEGORY_REQUEST",
      payload: { currentPage, postsPerPage },
    });
  }, [currentPage, postsPerPage]);

  useEffect(() => {
    if (isEnabledUsers === "b") {
      setTimeout(() => {
        setShowSmModal(false);
        setIsAddCategoryModalVisible(false);
        dispatch({
          type: "FETCH_ALL_MERCHANT_CATEGORY_REQUEST",
          payload: { currentPage, postsPerPage },
        });
      }, 1000);
    }
  }, [isEnabledUsers]);

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Manage Merchant Category</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Merchant Category</h4>
              <button
                onClick={() => {
                  setIsAddCategoryModalVisible(true);
                }}
              >
                Add <i className="bi bi-plus c-plus" />
              </button>
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="announcement--id">ID</th>
                  <th className="announcement--addedBy">Image</th>
                  <th className="announcement--body">Category Name</th>
                  <th className="announcement--addedBy">Status</th>

                  <th
                    className="announcement--actions"
                    style={{ minWidth: "100px" }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {allMerchantCategories.data?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>
                      <p className="campaing--heading--img">
                        <img
                          className="myLargeImage"
                          style={{ height: "60px", width: "60px" }}
                          src={item.image}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>{item.categoryName}</td>
                    <td>
                      <span
                        style={{
                          backgroundColor: "green",
                          borderRadius: "4px",
                          color: "white",
                        }}
                      >
                        {item.isActive ? "Active" : "Not Active"}
                      </span>
                    </td>
                    <td>
                      {item.isActive && (
                        <Tippy content="Sub Category" placement="bottom">
                          <Link to={`/manage-merchant-sub-category/${item.id}`}>
                            <span>
                              <CgMenuRound className="action--edit__admin" />{" "}
                            </span>
                          </Link>
                        </Tippy>
                      )}
                      <Tippy
                        content={
                          <span>
                            {item.isActive ? "De-activate" : "Activate"}
                          </span>
                        }
                        placement="top"
                      >
                        <span>
                          <BsCheckCircle
                            className="action--eye__admin"
                            onClick={() => {
                              setShowSmModal(true);
                              setEnableDisableItem(item);
                            }}
                          />{" "}
                        </span>
                      </Tippy>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* add category */}
            <div
              className={`${
                isAddCategoryModalVisible
                  ? "mymodal modal_activated"
                  : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <AddMerchantCategory />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        dispatch({
                          type: "FETCH_MERCHANT_CATEGORY_REQUEST",
                        });
                        setIsAddCategoryModalVisible(
                          !isAddCategoryModalVisible
                        );
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>
            {/* for Enable/Disable category  */}
            <div
              className={`${
                showSmModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading"></div>
                <>
                  <div className="delete--admin--container__body">
                    {enableDisableItem.isActive ? (
                      <h1>Are you sure to Disable this ??</h1>
                    ) : (
                      <h1>Are you sure to Enable this ??</h1>
                    )}
                    <div>
                      {isEnabledUsers === "a" && (
                        <div className="deleting--something">
                          {" "}
                          <p>Processing...</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "b" && (
                        <div className="deleting--something">
                          {" "}
                          <TiTick className="success--icon" /> <p>Successful</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "c" && (
                        <div className="deleting--something">
                          {" "}
                          <GiSkullCrossedBones
                            className="success--icon"
                            style={{ fontSize: "30px" }}
                          />{" "}
                          <p>Try Again</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button onClick={() => setShowSmModal(!showSmModal)}>
                      Cancel
                    </button>
                    {enableDisableItem.isActive ? (
                      <button
                        onClick={() => {
                          let enableDisableId = enableDisableItem.id;
                          dispatch({
                            type: "TOGGLE_STATUS_FOR_CATEGORY_REQUEST",
                            payload: {
                              id: enableDisableId,
                              status: "inactive",
                            },
                          });
                        }}
                      >
                        Ok
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          let enableDisableId = enableDisableItem.id;
                          dispatch({
                            type: "TOGGLE_STATUS_FOR_CATEGORY_REQUEST",
                            payload: {
                              id: enableDisableId,
                              status: "active",
                            },
                          });
                        }}
                      >
                        Ok
                      </button>
                    )}
                  </div>
                </>
              </div>
            </div>

            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > allMerchantCategories.totalData
                  ? allMerchantCategories.totalData
                  : indexOfLastPost}{" "}
                of {allMerchantCategories.totalData} entries
              </p>
              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default MerchantCategoryList;
