import React, { useState, useRef, useEffect } from "react";
import moment from "moment";

import { useDispatch, connect } from "react-redux";
import guruGLogo from "../../Images/foocery-logo-sm.png";
import { GiFlowerPot } from "react-icons/gi";

function RestroDetails({ id, item }) {
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  moment.locale("en");
  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };
  useEffect(() => {
    let x = getLocalDateTime(`2021-01-03 ${item.openingTime}`).split(" ")[1];

    let y = getLocalDateTime(`2020-01-01 ${item.closingTime}`).split(" ")[1];
    setOpeningTime(x);
    setClosingTime(y);
  }, [item]);
  return (
    <div>
      <div className="Modal--heading">
        <span style={{ color: "#3e445e" }}>Merchant's Details</span>
      </div>
      <div className="delivery--profile">
        <div className="delivery--profile__background">
          <div className="delivery--profile__background--color"></div>
        </div>
        <div className="delivery--profile__details">
          <div className="profile--header">
            <div className="profile--header__image">
              <img src={item.imageUrl} alt="Profile Image" />
            </div>
            <div className="profile--header__fullName">
              <p>{item.restaurantName}</p>
              <p>{item.slogan}</p>
              <p>{item.email}</p>
            </div>
          </div>
          <div className="profile--body">
            <div className="profile--body--children">
              <p>Category : {item?.category?.categoryName}</p>
              <div className="profile--body--children__text">
                <p>Sub-Category : {item?.subCategory?.categoryName}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>Contact Person Phone No. : {item.contactPersonPhone}</p>
              <div className="profile--body--children__text">
                <p>Contact Person Name : {item.contactPersonName}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>Comission Rate : {item.commissionRate}</p>
              <div className="profile--body--children__text">
                <p>Location : {item.location}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>Latitude : {item.latitude}</p>
              <div className="profile--body--children__text">
                <p>Longitude : {item.longitude}</p>
              </div>
            </div>

            <div className="profile--body--children">
              <p>
                Deleted Status :{" "}
                <span>{item.deleted ? "Deleted" : "Not Deleted"}</span>
              </p>
              <div className="profile--body--children__text">
                <p>
                  Active Status :{" "}
                  <span>{item.active ? "Active" : "False"}</span>
                </p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>
                Opening Times :{" "}
                {moment(openingTime, "HH:mm:ss").format("hh:mm a")}
                {/* {moment(`${item.openingTime}`, "hh:mm:00")
                  .local()
                  .format("hh:mm:00")} */}
              </p>
              <div className="profile--body--children__text">
                <p>
                  Closing Time :{" "}
                  {moment(closingTime, "HH:mm:ss").format("hh:mm a")}
                  {/* {item.closingTime} */}
                </p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>
                Open Status : <span>{item.isOpen ? "Yes" : "No"}</span>
              </p>
              <div className="profile--body--children__text">
                <p>Operating Location : {item.operatingLocation}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>
                Is Supplier : <span>{item.isSupplier ? "Yes" : "No"}</span>
              </p>
              <div className="profile--body--children__text">
                <p>
                  Order Modes:{" "}
                  {item.orderModes?.map((item) => {
                    return (
                      <span
                        style={{
                          background: "none",
                          color: "black",
                        }}
                      >
                        {item}
                      </span>
                    );
                  })}
                </p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>Delivery Option : {item.deliveryOption}</p>
              <div className="profile--body--children__text"></div>
            </div>
            <div className="profile--body--description">
              <p>Description : </p>
              <p>{item.description}</p>
            </div>
          </div>
        </div>
        <div
          className="delivery--profile__footer"
          style={{ marginTop: "-40px" }}
        ></div>
      </div>
    </div>
  );
}

export default RestroDetails;
