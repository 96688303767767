import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsX, BsCheckCircle, BsEyeFill } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AssignDriver from "./DriverAssign/AssignDriver";
import BatchDetail from "./BatchDetail";

function BatchPakageList() {
  const { isEnabledUsers, isLoadingOnLogin, isToogleSidebar, bachedList } =
    useSelector((state) => state.mainState);

  const [showOptionModal, setShowOptionModal] = useState(false);
  const [showAssignDriverModal, setShowAssignDriverModal] = useState(false);
  const [openEye, setOpenEye] = useState(false);
  const [detailsShoingItem, setDetailsShhowingItem] = useState(null);

  const [showSmModal, setShowSmModal] = useState(false);
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [assigningBatchId, setAssigningBatchId] = useState(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPosts, setCurrentPosts] = useState([]);

  let pageNumbers = [];

  console.log("the bachedList in the cmponent", bachedList);

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  for (let i = 1; i <= bachedList.totalPage; i++) {
    pageNumbers.push(i);
  }

  const [rate, setRate] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_NEW_BATCH_REQUEST",
      payload: { currentPage, postsPerPage },
    });
  }, [currentPage, postsPerPage]);

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Manage Batch Package</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Batch Package</h4>
              <button onClick={() => setShowOptionModal(true)}>
                Add <i className="bi bi-plus c-plus" />
              </button>
            </div>
            <div className="user_groups--button_n_search--search">
              <input type="text" placeholder="Search" />
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="announcement--id">S.N</th>
                  <th className="announcement--body">Batch Package Name</th>
                  <th className="announcement--body">Package Type</th>
                  <th className="announcement--addedBy">
                    Commission Amount(FLAT)
                  </th>
                  <th className="announcement--addedBy">Status</th>
                  <th className="announcement--addedBy">Assigned To</th>
                  <th
                    className="announcement--actions"
                    style={{ minWidth: "100px" }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {bachedList.data?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.packageName}</td>
                    <td>{item.packageType}</td>
                    <td>{item.commissionAmount}</td>
                    <td>{item.isCompleted ? "Completed" : "Pending"}</td>
                    <td>{item.assignedToUser}</td>
                    <td style={{ display: "flex", flexDirection: "column" }}>
                      <Tippy
                        content={
                          <span>
                            {item.isActive ? "De-activate" : "Activate"}
                          </span>
                        }
                        placement="top"
                      >
                        <span>
                          <BsCheckCircle
                            className="action--eye__admin"
                            onClick={() => {
                              setShowSmModal(true);
                              setEnableDisableItem(item);
                            }}
                          />{" "}
                        </span>
                      </Tippy>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* for active de-activation of announcement */}
            <form>
              <div
                className={`${
                  showOptionModal ? "delete--admin" : "delete--admin__hidden"
                }`}
              >
                <div className="delete--admin--container">
                  <div className="batch-option--container">
                    <div className="batch-option--container__options">
                      {/* <Link to="/add-normalorder-batch-package"> <p>Normal Order</p></Link> */}
                      <Link to="/add-preorder-batch-package">
                        {" "}
                        <p>Pre Order</p>
                      </Link>
                      <Link to="/add-campaignorder-batch-package">
                        {" "}
                        <p>Campaign Order</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* edit announcement modal*/}
            <div
              className={`${
                showEditModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {/* {editingId !== null && <EditAnnouncement id={editingId} myBody={announcementBody} />} */}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setShowEditModal(false);
                        dispatch({
                          type: "FETCH_USER_ANNOUNCEMENT_REQUEST",
                          payload: { currentPage, postsPerPage },
                        });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Edit</p>
                  </div>
                </div>
              </div>
            </div>
            {/* assign driver modal */}
            <div
              className={`${
                showAssignDriverModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <AssignDriver batchId={assigningBatchId} />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span onClick={() => setShowAssignDriverModal(false)}>
                      <BsX />
                    </span>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            {/*  Show batchd details */}
            <div
              className={`${openEye ? "mymodal modal_activated" : "mymodal"}`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <BatchDetail item={detailsShoingItem} />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span onClick={() => setOpenEye(false)}>
                      <BsX />
                    </span>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > bachedList.totalData
                  ? bachedList.totalData
                  : indexOfLastPost}{" "}
                of {bachedList.totalData} entries
              </p>
              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default BatchPakageList;
