import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "tippy.js/dist/tippy.css";
import LoadingComponent from "../../LoadingComponent";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import moment from "moment";
import { Avatar, Button, List, Tag } from "antd";
import AddMenu from "./AddMenu";
import { BsX } from "react-icons/bs";
import UpdateMenu from "./UpdateMenu";

const mapStateToProps = (store) => {
  const {
    isLoadingOnLogin,
    resellerDetail,
    menuList,
    isToogleSidebar,
    isOperationSuccessful,
  } = store.mainState;
  return {
    isLoadingOnLogin,
    resellerDetail,
    menuList,
    isToogleSidebar,
    isOperationSuccessful,
  };
};

function Menu({
  id,
  isLoadingOnLogin,
  menuList,
  isToogleSidebar,
  isOperationSuccessful,
}) {
  const dispatch = useDispatch();
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const [edtingItem, setEditingItem] = useState(null);
  const [activateModal, setActivateModal] = useState("");
  const [deactivateModal, setDeactivateModal] = useState("");
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [activateConfirmationText, setactivateConfirmationText] = useState("");
  const [deactivateConfirmationText, setdeactivateConfirmationText] =
    useState("");

  useEffect(() => {
    if (id) {
      dispatch({
        type: "FETCH_MENU_LIST_REQUEST",
        payload: {
          id: id,
        },
      });
    }
  }, [id, isOperationSuccessful]);

  useEffect(() => {
    if (isOperationSuccessful) {
      setIsAddMenuOpen(false);
      setIsEditMenuOpen(false);
      setActivateModal(false);
      setDeactivateModal(false);
    }
  }, [isOperationSuccessful]);

  return (
    <div>
      <div className="Modal--heading">
        <span style={{ color: "#3e445e" }}>Menu Lists</span>
        <Button
          style={{
            marginLeft: "1rem",
            background: "#9B129B",
            color: "red",
            border: "none",
          }}
          type="primary"
          onClick={() => {
            setIsAddMenuOpen(true);
          }}
        >
          Add
        </Button>
      </div>
      <p className="privacy-loading--onFetching">
        {isLoadingOnLogin && <LoadingComponent />}
      </p>
      <List
        itemLayout="horizontal"
        dataSource={menuList.data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsEditMenuOpen(true);
                  setEditingItem("");
                  setEditingItem(item);
                }}
              >
                edit
              </Button>,
              <Button
                onClick={() => {
                  if (item.isActive) {
                    setDeactivateModal(true);
                  } else {
                    setActivateModal(true);
                  }
                  setEnableDisableItem(item);
                }}
              >
                {item.isActive ? "Deactivate" : "Activate"}
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{ height: "60px", width: "60px" }}
                  src={item.imageUrl}
                />
              }
              title={
                <span>
                  {item.itemName} ( $ {item.price} )
                  <span>
                    {item.isActive ? (
                      <Tag color="green">Active</Tag>
                    ) : (
                      <Tag color="red">Not Active</Tag>
                    )}
                  </span>
                </span>
              }
              description={item.description}
            />
          </List.Item>
        )}
      />

      <div
        className={`${
          activateModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div
            className="delete--admin--container__heading"
            style={{ color: "black" }}
          >
            Are you sure want to activate this Menu ?
          </div>
          <>
            <div className="delete--admin--container__body">
              <div className="reason--enableDisable">
                <label>Confirmation Text : </label>
                <textarea
                  type="text"
                  style={{
                    border: "1px solid #d7dbd8",
                    borderRadius: "5px",
                  }}
                  placeholder="Confirmation Text"
                  value={activateConfirmationText}
                  onChange={(e) => setactivateConfirmationText(e.target.value)}
                ></textarea>
              </div>
              {isLoadingOnLogin && <LoadingComponent />}
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setActivateModal(!activateModal)}>
                Cancel
              </button>

              <button
                onClick={() => {
                  dispatch({
                    type: "ACTIVATE_MENU_REQUEST",
                    payload: {
                      id: enableDisableItem.id,
                      values: {
                        confirmationText: activateConfirmationText,
                      },
                    },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      <div
        className={`${
          deactivateModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div
            className="delete--admin--container__heading"
            style={{ color: "black" }}
          >
            Are you sure want to deactivate this Menu ?
          </div>
          <>
            <div className="delete--admin--container__body">
              <div className="reason--enableDisable">
                <label>Confirmation Text : </label>
                <textarea
                  type="text"
                  style={{
                    border: "1px solid #d7dbd8",
                    borderRadius: "5px",
                  }}
                  placeholder="Confirmation Text"
                  value={deactivateConfirmationText}
                  onChange={(e) =>
                    setdeactivateConfirmationText(e.target.value)
                  }
                ></textarea>
              </div>
              {isLoadingOnLogin && <LoadingComponent />}
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setDeactivateModal(!deactivateModal)}>
                Cancel
              </button>

              <button
                onClick={() => {
                  dispatch({
                    type: "DEACTIVATE_MENU_REQUEST",
                    payload: {
                      id: enableDisableItem.id,
                      values: {
                        confirmationText: deactivateConfirmationText,
                      },
                    },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>

      <div
        className={`${isAddMenuOpen ? "mymodal modal_activated" : "mymodal"}`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          {<AddMenu categoryId={id} />}
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  setIsAddMenuOpen(false);
                  dispatch({
                    type: "FETCH_MENU_LIST_REQUEST",
                    payload: {
                      id: id,
                    },
                  });
                }}
              >
                <BsX />
              </span>
              <p>Close</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${isEditMenuOpen ? "mymodal modal_activated" : "mymodal"}`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          {edtingItem !== null && <UpdateMenu item={edtingItem} />}
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  setEditingItem(" ");
                  setIsEditMenuOpen(false);
                  dispatch({
                    type: "FETCH_MENU_LIST_REQUEST",
                    payload: {
                      id: id,
                    },
                  });
                }}
              >
                <BsX />
              </span>
              <p>Close</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(Menu);
