import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import EditServiceRate from "./EditServiceRate";
import moment from "moment";

const mapStateToProps = (store) => {
  const { buttonText, isLoadingOnLogin, serviceRateData, isToogleSidebar } =
    store.mainState;
  return { buttonText, isLoadingOnLogin, serviceRateData, isToogleSidebar };
};

function ServiceRate({
  buttonText,
  isLoadingOnLogin,
  serviceRateData,
  isToogleSidebar,
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [rate, setRate] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_SERVICE_RATE_REQUEST",
    });
  }, []);
  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };
  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Manage Service Rate</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Service Rate</h4>
              <button
                onClick={() => dispatch({ type: "OPEN_SERVICE_RATE_MODAL" })}
              >
                Add <i className="bi bi-plus c-plus" />
              </button>
            </div>
            <div className="user_groups--button_n_search--search">
              <input type="text" placeholder="Search" />
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="loyality--id">ID</th>
                  <th
                    className="loyality--schemeName"
                    style={{ width: "20vw" }}
                  >
                    Scheme Name
                  </th>
                  <th className="loyality--addedBy">Added By</th>
                  <th className="loyality--addedBy">Added Date</th>
                  <th className="loyality--addedBy">Updated By</th>
                  <th className="loyality--addedBy">Updated Date</th>
                  <th className="loyality--loyalityPoint">Rate</th>
                  <th className="loyality--actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {serviceRateData !== undefined && (
                  <tr>
                    <td>1</td>
                    <td>{serviceRateData.configKey}</td>
                    <td>{serviceRateData.addedBy}</td>
                    <td>{getLocalDateTime(serviceRateData.addedDateTime)}</td>
                    <td>{serviceRateData.lastUpdatedBy}</td>
                    <td>
                      {getLocalDateTime(serviceRateData.lastUpdatedDateTime)}
                    </td>
                    <td>{serviceRateData.doubleConfigValue}</td>
                    <td>
                      <Tippy content="Edit" placement="top">
                        <span>
                          <AiFillEdit
                            className="edit--icon"
                            onClick={() => {
                              setShowEditModal(true);
                              setRate(serviceRateData.doubleConfigValue);
                            }}
                          />{" "}
                        </span>
                      </Tippy>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* add referral And loyalty modal */}
            <div
              className={`${
                showEditModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {rate !== null && (
                  <EditServiceRate
                    myRate={rate}
                    setShowEditModal={setShowEditModal}
                  />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setShowEditModal(false);
                        dispatch({
                          type: "FETCH_SERVICE_RATE_REQUEST",
                        });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Edit</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination">
              <p>Showing 0 to 1 of 1 entries</p>
              <nav aria-label="...">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronLeft />
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronRight />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(ServiceRate);
