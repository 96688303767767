import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import { Descriptions, Divider } from "antd";
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { BsEyeFill, BsCheckCircle } from "react-icons/bs";
import { BsX } from "react-icons/bs";
import { MdAssignmentTurnedIn, MdDelete } from "react-icons/md";
// import UserDetails from './UserDetails';
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { AiFillEdit } from "react-icons/ai";
import dayjs from "dayjs";
import { Button } from "antd";
import { Tabs } from "antd";
import "./Settlement.css";
import { Card, Col, Row, Statistic, Input, DatePicker } from "antd";
import moment from "moment";

const { TabPane } = Tabs;
const { Search } = Input;
const { RangePicker } = DatePicker;

const mapStateToProps = (store) => {
  const {
    isLoadingOnLogin,
    settlementData,
    isToogleSidebar,
    isEnabledUsers,
    exportButton,
    isSettlementSuccess,
  } = store.mainState;
  return {
    isLoadingOnLogin,
    settlementData,
    isToogleSidebar,
    isEnabledUsers,
    exportButton,
    isSettlementSuccess,
  };
};

function OrderSummary({
  isLoadingOnLogin,
  settlementData,
  isToogleSidebar,
  isEnabledUsers,
  exportButton,
  isSettlementSuccess,
  restaurantDetails,
  orderState,
}) {
  const [startDate, setStartDate] = useState("2020-01-01 00:00:00");
  const [endDate, setEndDate] = useState("2024-01-01 00:00:00");
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const dispatch = useDispatch();

  for (let i = 1; i <= settlementData.totalPage; i++) {
    pageNumbers.push(i);
  }
  console.log("the settlemnt Data", settlementData);

  const tableBody = () =>
    restaurantDetails.orderDetails !== undefined &&
    restaurantDetails.orderDetails?.map((item, index) => (
      <tr key={index + 1}>
        <td>{index + 1}</td>
        <td>{item.customerOrderId}</td>
        <td>{item.customerInfo.fullName}</td>
        <td> {item.slipNo}</td>
        <td>{moment(item.checkOutDateTime).format("YYYY-MM-DD")}</td>
        <td>{moment(item.deliveredTime).format("YYYY-MM-DD")}</td>
        <td>{item.paymentMethod}</td>
        <td>
          {" "}
          <span> $ {item.restaurantPayableFromOrder}</span>{" "}
        </td>
        <td>$ {item.commissionFromOrder}</td>

        <td style={{}}>
          <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
            {" "}
            {item.restaurantCommission}%{" "}
          </span>{" "}
          <span style={{ marginLeft: "15px" }}> $ {item.orderTotal}</span>
        </td>
        {orderState == "RELEASED" && (
          <td style={{ display: "flex" }}>
            <span>{item.settlementReleasedBy} </span>{" "}
            <span>{item.settlementReleasedDateTime}</span>{" "}
          </td>
        )}
      </tr>
    ));

  return (
    <>
      <div>
        <div className="group_list">
          <h4>Order Summary ( {restaurantDetails.restaurantName} )</h4>
        </div>

        <div className="user_groups">
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="settlement--sn">S.N</th>
                  <th className="settlement--name">Order ID</th>
                  <th className="settlement--contactName">Customer Name</th>
                  <th className="settlement--mobile">Bill No</th>
                  <th className="settlement--commission">Date Of Order</th>
                  <th className="settlement--commission">Delivery Time</th>
                  <th className="settlement--commission">Payment Method</th>
                  <th
                    className="settlement--commission"
                    style={{ width: "15vw" }}
                  >
                    Restaurant Pay
                  </th>
                  <th className="settlement--commission">Commission</th>
                  <th className="settlement--commission">Total</th>
                  {orderState == "RELEASED" && (
                    <th className="settlement--commission">Released By</th>
                  )}
                </tr>
              </thead>
              <tbody>{tableBody()}</tbody>
            </table>
          </div>
        </div>
        <Descriptions
          title="Payment Summary"
          layout="horizontal"
          className="order-summary"
        >
          <Descriptions.Item label="Item(s) Sold">
            {restaurantDetails.totalSales}
          </Descriptions.Item>
          <Descriptions.Item label="Item(s) Sold">
            {restaurantDetails.totalSales}
          </Descriptions.Item>
          <Descriptions.Item label="Restaurant Pay">
            $ {restaurantDetails.netPayoutAmount}
          </Descriptions.Item>
          <Descriptions.Item label="Commission ">
            $ {restaurantDetails.commissionAmount}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Tax Amount" >
      $ 0
    </Descriptions.Item> */}
          <Divider />
          <Descriptions.Item
            label="Grand Total"
            labelStyle={{
              color: "#1E5DE2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
            contentStyle={{
              color: "#1E5DE2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            $ {restaurantDetails.netPayoutAmount}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
}

export default connect(mapStateToProps, null)(OrderSummary);
