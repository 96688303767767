import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import moment from "moment";

const mapStateToProps = (store) => {
  const { buttonText, isLoadingOnLogin, creditRateData, isToogleSidebar } =
    store.mainState;
  return { buttonText, isLoadingOnLogin, creditRateData, isToogleSidebar };
};

function CreditCard({
  buttonText,
  isLoadingOnLogin,
  creditRateData,
  isToogleSidebar,
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [rate, setRate] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_CREDIT_RATE_REQUEST",
    });
  }, []);
  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };
  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Manage Credit Card</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Credit Surcharge</h4>
              <button
                onClick={() => dispatch({ type: "OPEN_CREDIT_CARD_MODAL" })}
              >
                Add <i className="bi bi-plus c-plus" />
              </button>
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="Credit--id">ID</th>
                  <th className="Credit--surcharge">
                    Credit Card Surcharge (%)
                  </th>
                  <th className="Credit--minamount">
                    Credit Card Minimum Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {creditRateData !== undefined && (
                  <tr>
                    <td>1</td>
                    <td>{creditRateData.creditCardSurcharge}</td>
                    <td>{creditRateData.creditCardMinimumAmount}</td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* add referral And loyalty modal */}

            <div className="pagination">
              <p>Showing 0 to 1 of 1 entries</p>
              <nav aria-label="...">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronLeft />
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronRight />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(CreditCard);
