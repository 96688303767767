import React, { useEffect, useState, useRef } from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { handleAdminCre_Input, loginRequest } from "../../../Redux/actions";
import OrkmateLogo from "../../../Images/foocery-logo.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ReCAPTCHA from "react-google-recaptcha";

const mapStateToProps = (store) => {
  const { adminCredencial, isLoadingOnLogin, isSuccessOnLogin } =
    store.mainState;
  return { adminCredencial, isLoadingOnLogin, isSuccessOnLogin };
};

const LogIn = ({
  handleAdminCre_Input,
  loginRequest,
  adminCredencial,
  isLoadingOnLogin,
  isSuccessOnLogin,
}) => {
  const [eyeState, setEyeState] = useState(false);
  const recaptchaRef = useRef();

  const { email, password } = adminCredencial;
  const handleInput = (name) => (event) => {
    handleAdminCre_Input({ ...adminCredencial, [name]: event.target.value });
  };
  if (isSuccessOnLogin) {
    window.location.replace("/dashboard");
  }

  return (
    <div className="auth--page">
      <div className="admin-login">
        <ToastContainer />
        <form>
          <div className="admin-login--heading">
            <p>Admin Login</p>
            <div className="admin-login--heading__imgContainer">
              <img src={OrkmateLogo} alt="Gurug Logo" />
            </div>
          </div>
          <div className="login_loading--text">
            {isLoadingOnLogin && "Loading..."}
            {isSuccessOnLogin && "Succeed!!"}
          </div>
          <div className="admin-login--body">
            <div className="email--field">
              <p>Email </p>
              <input
                type="email"
                placeholder="Enter Email"
                value={email}
                required="true"
                onChange={handleInput("email")}
              />
            </div>
            <div className="password--field">
              <p>Password </p>
              <input
                type={`${eyeState ? "text" : "password"}`}
                placeholder="Enter Password"
                required="true"
                value={password}
                onChange={handleInput("password")}
              />
              <span className="eye-icon">
                {eyeState ? (
                  <AiOutlineEyeInvisible
                    onClick={() => setEyeState(!eyeState)}
                  />
                ) : (
                  <AiOutlineEye onClick={() => setEyeState(!eyeState)} />
                )}
              </span>
            </div>
          </div>
          {/* <ReCAPTCHA

                ref={recaptchaRef}

                theme="light"
                size="invisible"
                    //  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" test key
                    sitekey="6Ldt4nggAAAAALrxmzAMm8UQrWtkMiDhRyhxes0W"

                    // onChange={handleReCaptchaChange}
                /> */}
          <div className="admin-login--footer">
            <button
              type="submit"
              onClick={async (e) => {
                e.preventDefault();
                loginRequest(adminCredencial);
              }}
            >
              Log In
            </button>
          </div>
          {/* <div className="admin-login--footer"><button type="submit" onClick={ async (e) => {
                    e.preventDefault()
                    const token = await recaptchaRef.current.executeAsync();
                    if(token) {
                        adminCredencial.captchaResponse = token
                        loginRequest(adminCredencial);
                    } else {
                        toast.error("Google ReCaptch Failed. Try Again Later!!!")
                    }
                    
                }}>Log In</button></div> */}
        </form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleAdminCre_Input: (values) => dispatch(handleAdminCre_Input(values)),
    loginRequest: (Credencial) => dispatch(loginRequest(Credencial)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
