import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import guruGLogo from "../../Images/foocery-logo-sm.png";
import moment from "moment";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
var geocoding = new require("reverse-geocoding");

const getLocalDateTime = (mydate) => {
  var stillUtc = moment.utc(mydate).toDate();
  var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
  return local;
};

function CampaignOrderDetails({ item, setOpenEyeCampaign }) {
  const dispatch = useDispatch();

  const [showApproveModal, setShowApproveModal] = useState(false);
  const [approvingItem, setApprovingitem] = useState(null);

  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [completingItem, setCompletingItem] = useState(null);

  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [cancelingItem, setCancelingItem] = useState(null);

  const { campaignData, deliveryData } = item;

  console.log("the campaign item", item);

  const { isLoadingOnLogin, isEnabledUsers } = useSelector(
    (state) => state.mainState
  );
  return (
    <div className="orderDetailsShowing--container">
      <div className="Modal--heading">
        <span style={{ color: "#3e445e" }}>Campaign Order Details</span>
      </div>
      <p className="privacy-loading--onFetching">
        {isLoadingOnLogin && <LoadingComponent />}
      </p>
      <div className="modal--print_export">
        <div className="modal--print_export--button">
          <button>Print</button>
          <button>Export As PDF</button>
        </div>
      </div>
      <hr />
      <div className="modal-orderDetails--heading">
        <div className="modal-orderDetails--heading__logo">
          <img src={guruGLogo} alt="Gurug Logo" />
        </div>
        <div className="modal-orderDetails--heading__description">
          <p>Guru-G Food</p>
          <p>Maitidevi, Kathmandu, Nepal</p>
          <p>021-590654, 9852030654</p>
          <p>contact@gurungfood.com</p>
        </div>
      </div>
      <hr />
      {/* <div className="campaignOrder--contactInfo">
      <div>
        <p>Order From : <span>{item.userFullName}</span></p>
        <p>Order From : <span>{item.userFullName}</span></p>
      </div>
    </div> */}

      <h4 style={{ marginLeft: "20px" }}>General Info : </h4>
      <div className="modal-orderDetails--body">
        <div className="campaignOrderDetails">
          <div className="campaignOrderDetails__img--container">
            <img src={campaignData?.campaignImage} alt="" />
          </div>
          <div className="campaignOrderDetails__img--details">
            <div className="campaignOrderDetails--item">
              <p>
                Customer Name : <span>{item.userFullName}</span>
              </p>
              <p>
                Status : <span>{item.completionStatus}</span>
              </p>
            </div>
            <div className="campaignOrderDetails--item">
              <p>
                Price : <span> $ {campaignData.price}</span>
              </p>
              <p>
                Quantity : <span>{item.quantity}</span>
              </p>
            </div>
            <div className="campaignOrderDetails--item">
              <p>
                Order Date :{" "}
                <span style={{ fontWeight: "bold" }}>
                  {moment
                    .utc(item?.createdOn)
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss")}
                </span>
              </p>
              <p>
                Delivery Date :{" "}
                <span style={{ fontWeight: "bold" }}>
                  {moment
                    .utc(item?.deliveryData?.scheduledTime)
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss")}
                </span>
              </p>
            </div>
            <div className="campaignOrderDetails--item">
              <p>
                Payment Method :{" "}
                <span
                  style={{
                    backgroundColor: "green",
                    padding: "3px 5px",
                    borderRadius: "4px",
                    fontSize: "12px",
                    color: "wheat",
                  }}
                >
                  {item.paymentMethod}
                </span>
              </p>
              <p>
                Description : <span>{campaignData.description}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-orderDetails--footer">
        <div className="modal-orderDetails--footer__orderTo">
          <div className="modal-orderDetails--footer_button"></div>
          <table>
            <thead>
              <tr>
                <th className="campaignOrder--image">Image</th>
                <th className="campaignOrder--itemName">Item Name</th>
                <th className="campaignOrder--quantity">Quantity</th>
                {/* <th className="campaignOrder--price">Price($)</th> */}
              </tr>
            </thead>
            <tbody>
              {campaignData?.itemData !== undefined &&
                campaignData.itemData.map((item, index) => (
                  <tr key={index}>
                    <td loyality--id>
                      <img src={item.itemImage} alt="" />
                    </td>
                    <td>{item.itemName}</td>
                    <td>{item.quantity}</td>
                    {/* <td>{item.price}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="campaignOrder--Footer">
        {item.completionStatus === "PENDING" && (
          <div className="button--controll">
            <div className="button--controll--facebook">
              <p
                onClick={() => {
                  setShowApproveModal(true);
                  setApprovingitem(item);
                }}
              >
                <span>Approve Order</span>
              </p>
            </div>
          </div>
        )}
        {item.completionStatus === "APPROVED" && (
          <div className="button--controll">
            <div className="button--controll--facebook">
              <p
                onClick={() => {
                  setShowCompleteModal(true);
                  setCompletingItem(item);
                }}
              >
                <span>Complete Order</span>
              </p>
            </div>
          </div>
        )}
        {item.completionStatus !== "REJECTED" && (
          <div className="button--controll">
            <div className="button--controll--facebook">
              <p
                onClick={() => {
                  setCancelingItem(item);
                  setShowCancelOrderModal(true);
                }}
              >
                <span>Cancel Order</span>
              </p>
            </div>
          </div>
        )}
      </div>

      <h1 style={{ margin: "20px 20px 5px 20px" }}>Delivery Info :</h1>
      {campaignData !== undefined && (
        <div className="delivery--info">
          <div className="delivery--info__firstContainer">
            <p>
              Assinged To: <span>{deliveryData?.deliveryAssignedTo}</span>
            </p>
            <p>
              Delivery Status:{" "}
              <span>
                {deliveryData.deliveryStatus ? "Delivered" : "Not Delivered"}
              </span>
            </p>
            <p>
              Driver Note:{" "}
              <span style={{ color: "green" }}>{deliveryData.driverNote}</span>
            </p>
          </div>
          <div className="delivery--info__secondContainer">
            <div className="delivery--info__secondContainer--text">
              <p>
                Delivery Address: <span>{deliveryData.deliveryAddress}</span>
              </p>
              <p>
                Contact Person: <span>{deliveryData?.deliveryFullName}</span>
              </p>
              <p>
                Contact Number:{" "}
                <span>
                  {deliveryData.deliveryContactNo},
                  {deliveryData.deliveryAlternateContactNo}
                </span>
              </p>
              <p>
                Scheduled Time:{" "}
                <span>
                  {deliveryData.scheduledTime !== null
                    ? getLocalDateTime(deliveryData.scheduledTime)
                    : "Not Scheduled"}
                </span>
              </p>
            </div>
          </div>
        </div>
      )}

      {/* approve order */}

      <div
        className={`${
          showApproveModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h3>Are you sure to approve this campaign ?</h3>
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setShowApproveModal(false)}>Cancel</button>
              <button
                onClick={() => {
                  let orderId = item.id;
                  dispatch({
                    type: "APPROVE_CAMPAIGN_ORDER_REQUEST",
                    payload: { orderId },
                  });
                  setShowApproveModal(false);
                  setOpenEyeCampaign(false);
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      {/* Complete campaign order */}
      <div
        className={`${
          showCompleteModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h3>Are you sure to complete this campaign ?</h3>
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setShowCompleteModal(false)}>
                Cancel
              </button>
              <button
                onClick={() => {
                  let orderId = item.id;
                  console.log("orderid", item.id);
                  dispatch({
                    type: "COMPLETE_CAMPAIGN_ORDER_REQUEST",
                    payload: { orderId },
                  });
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
      {/* Cancel campaign order */}
      <div
        className={`${
          showCancelOrderModal ? "delete--admin" : "delete--admin__hidden"
        }`}
      >
        <div className="delete--admin--container">
          <div className="delete--admin--container__heading">
            <h3>Are you sure to cancel this campaign ?</h3>
          </div>
          <>
            <div className="delete--admin--container__body">
              <div>
                {isEnabledUsers === "a" && (
                  <div className="deleting--something">
                    {" "}
                    <p>Processing...</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "b" && (
                  <div className="deleting--something">
                    {" "}
                    <TiTick className="success--icon" /> <p>Successful</p>
                  </div>
                )}
              </div>
              <div>
                {" "}
                {isEnabledUsers === "c" && (
                  <div className="deleting--something">
                    {" "}
                    <GiSkullCrossedBones
                      className="success--icon"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    <p>Try Again</p>
                  </div>
                )}
              </div>
            </div>
            <div className="delete--admin--container__footer">
              <button onClick={() => setShowCancelOrderModal(false)}>
                Cancel
              </button>
              <button
                onClick={() => {
                  let orderId = cancelingItem.id;
                  dispatch({
                    type: "CANCEL_CAMPAIGN_ORDER_REQUEST",
                    payload: {
                      orderId,
                    },
                  });
                  setOpenEyeCampaign(false);
                }}
              >
                Ok
              </button>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default CampaignOrderDetails;
