import React, { useState, useRef, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import guruGLogo from "../../Images/foocery-logo-sm.png";
import { GiFlowerPot } from "react-icons/gi";

const mapStateToProps = (store) => {
  const { deliveryDetails, isLoadingOnLogin } = store.mainState;
  return { deliveryDetails, isLoadingOnLogin };
};

function DraftDriverDetails({ item, deliveryDetails, isLoadingOnLogin }) {
  const dispatch = useDispatch();

  console.log("the item in haha page", item);

  return (
    <div>
      <div className="Modal--heading">
        <span style={{ color: "#3e445e" }}>Web Draft Delivery Details</span>
      </div>
      <div className="delivery--profile">
        <div className="delivery--profile__background">
          <div className="delivery--profile__background--color"></div>
        </div>
        <div className="delivery--profile__details">
          <div className="profile--header">
            <div className="profile--header__image">
              <img src={item.userImage} alt="Profile Image" />
            </div>
            <div className="profile--header__fullName">
              <p>{item.fullName}</p>
              <p>@ {item.userName}</p>
              <p>{item.gender}</p>
            </div>
          </div>
          <div className="profile--body">
            <div className="profile--body--children">
              <p>Email : {item.email}</p>
              <div className="profile--body--children__text">
                <p>Phone No : {item.phoneNumber}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>DOB : {item.dob}</p>
              <div className="profile--body--children__text">
                <p>Location : {item.location}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>Identification Type : {item.identificationType}</p>
              <div className="profile--body--children__text">
                <p>Identification No. : {item.identificationNo}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>Bike Model : {item.bikeModel}</p>
              <div className="profile--body--children__text">
                <p>Bike Number : {item.bikeNo}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>
                Resistration Status : <span>{item.registrationStatus}</span>
              </p>
              <div className="profile--body--children__text">
                <p>
                  Active Status :{" "}
                  <span>{item.isActive ? "Active" : "Not Active"}</span>
                </p>
              </div>
            </div>
            <h3>Vehicle Info:</h3>
            <div className="profile--body--children">
              <p>Vehicle Type : Bike</p>
              <div className="profile--body--children__text">
                <p>Bike Number : 78879</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>Bike Model : 1212d3</p>
              <div className="profile--body--children__text">
                <p>Blue Book Number : 1212d3</p>
              </div>
            </div>
            <div className="webdraft--vehicleImg">
              <div>
                <img
                  src="https://restaurant-dev.s3-ap-south-1.amazonaws.com/gurug-food/2021-12-24T09:14:46.220395delivery-user-image-6671758977250253148.jpeg"
                  alt="img"
                />
                <img
                  src="https://restaurant-dev.s3-ap-south-1.amazonaws.com/gurug-food/2021-12-24T09:14:46.220395delivery-user-image-6671758977250253148.jpeg"
                  alt="img"
                />
                <img
                  src="https://restaurant-dev.s3-ap-south-1.amazonaws.com/gurug-food/2021-12-24T09:14:46.220395delivery-user-image-6671758977250253148.jpeg"
                  alt="img"
                />
                <img
                  src="https://restaurant-dev.s3-ap-south-1.amazonaws.com/gurug-food/2021-12-24T09:14:46.220395delivery-user-image-6671758977250253148.jpeg"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="delivery--profile__footer">
                    <h2>lets do it</h2>
                </div> */}
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(DraftDriverDetails);
