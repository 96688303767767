import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { Divider } from "antd";

const mapStateToProps = (store) => {
  const { buttonText, isEnabledUsers } = store.mainState;
  return { buttonText, isEnabledUsers };
};

function AddPreOTimeSlot({ buttonText, preOrderId, isEnabledUsers, myDates }) {
  const dispatch = useDispatch();
  const [removingItem, setRemovingItem] = useState({});
  const [preOrderDate, setPreOrderDate] = useState("");
  const [timeSlotItem, setTimeSlotItem] = useState([
    {
      startHour: "1",
      endHour: "1",
      totalOrder: "",
    },
  ]);

  const handleTimeSlotOnChange = (index, event) => {
    let values = [...timeSlotItem];
    values[index][event.target.name] = event.target.value;
    setTimeSlotItem(values);
  };

  const handleAddTimeSlot = () => {
    setTimeSlotItem([
      ...timeSlotItem,
      {
        startHour: "",
        endHour: "",
        totalOrder: "",
      },
    ]);
  };

  const handleAddTimeSlotSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: "POST_PRE_ORDER_TIME_SLOT_REQUEST",
      payload: {
        preOrderId: preOrderId,
        preOrderDate: preOrderDate,
        timeRequests: timeSlotItem,
      },
    });
  };
  useEffect(() => {
    if (isEnabledUsers === "b") {
      setTimeSlotItem([
        {
          startHour: "",
          endHour: "",
          totalOrder: "",
        },
      ]);
    }
  }, [isEnabledUsers]);
  useEffect(() => {
    let x = timeSlotItem.filter(
      (item) => item.startHour !== removingItem.startHour
    );
    setTimeSlotItem(x);
  }, [removingItem]);

  return (
    <div>
      <ToastContainer />
      <div className="billing__Modal--heading">
        <span style={{ color: "#3e445e" }}>Add Pre-Order Time Slot</span>
        <button className="addBilling--new" onClick={handleAddTimeSlot}>
          Add
        </button>
      </div>

      <div className="Modal--form" style={{ marginTop: "100px" }}>
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Pre Order Date: </label>
              <input
                type="date"
                value={preOrderDate}
                onChange={(event) => setPreOrderDate(event.target.value)}
                min={myDates?.startDate.split("T")[0]}
                max={myDates?.endDate.split("T")[0]}
              />
            </p>
          </div>
          <Divider />
          {timeSlotItem?.map((item, index) => (
            <Fragment key={index}>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="added_by">Start Time: </label>
                  <select
                    value={timeSlotItem[index]?.startHour}
                    name="startHour"
                    placeholder="Start Time"
                    onChange={(event) => handleTimeSlotOnChange(index, event)}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                  </select>
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="added_by">End Time: </label>
                  <select
                    value={timeSlotItem[index]?.endHour}
                    name="endHour"
                    placeholder="End Time"
                    onChange={(event) => handleTimeSlotOnChange(index, event)}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                  </select>
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="added_by">Total Order: </label>
                  <input
                    type="number"
                    value={timeSlotItem[index]?.totalOrder}
                    name="totalOrder"
                    placeholder="Total Order"
                    onChange={(event) => handleTimeSlotOnChange(index, event)}
                  />
                </p>
              </div>
              {timeSlotItem.length > 1 && (
                <div className="remove--timeSlot__btn">
                  <div onClick={() => setRemovingItem(item)}>Remove</div>
                </div>
              )}
            </Fragment>
          ))}

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleAddTimeSlotSubmit}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddPreOTimeSlot);
