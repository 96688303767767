import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import { GrLocation } from "react-icons/gr";
import { FaCheck } from "react-icons/fa";
import dayjs from "dayjs";
import GetValidLocation from "./GetValidLocation";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
import moment from "moment";
import { Button, Radio } from "antd";

const mapStateToProps = (store) => {
  const { buttonText, isEnabledUser, searchRestaurantList } = store.mainState;
  return { buttonText, isEnabledUser, searchRestaurantList };
};

function EditPreOrder({
  buttonText,
  isEnabledUser,
  editinPreOrderItem,
  searchRestaurantList,
}) {
  const dispatch = useDispatch();
  const innerClassRef = useRef();

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [planName, setPlanName] = useState(null);
  const [planDescription, setPlanDescription] = useState(null);
  const [planTitle, setPlanTitle] = useState(null);
  const [planContactName, setPlanContactName] = useState(null);
  const [planContactPhone, setPlanContactPhone] = useState(null);
  const [planContactEmail, setPlanContactEmail] = useState(null);
  const [restaurantId, setRestaurantId] = useState(null);
  // const [minutes,setMinutes] = useState(null)
  const [preOrderStartDate, setPreOrderStartDate] = useState(null);
  const [preOrderStartDateFinal, setPreOrderStartDateFinal] = useState(null);
  const [preOrderEndDate, setPreOrderEndDate] = useState(null);
  const [preOrderEndDateFinal, setPreOrderEndDateFinal] = useState(null);

  const [planPrice, setPlanPrice] = useState(null);
  const [deliveryStartDate, setDeliveryStartDate] = useState(null);
  const [deliveryStartDateFinal, setDeliveryStartDateFinal] = useState(null);
  const [deliveryEndDate, setDeliveryEndDate] = useState(null);
  const [deliveryEndDateFinal, setDeliveryEndDateFinal] = useState(null);

  const [
    deliveryPricePerKmAfterValidRadius,
    setDeliveryPricePerKmAfterValidRadius,
  ] = useState("");

  const [deliveryValidLocation, setDeliveryValidLocation] = useState(null);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [latitude2, setLatitude2] = useState("");
  const [longitude2, setLongitude2] = useState("");
  const [stallLocation, setStallLocation] = useState("");
  const [stallName, setStallName] = useState("");

  const [validRadius, setValidRadius] = useState(null);

  const [maxRequestCount, setMaxRequestCount] = useState(null);
  const [minimumRequestCount, setMinimumRequestCount] = useState(null);
  const [isGlobalDelivery, setIsGlobalDelivery] = useState(false);
  const [myRestaurantName, setMyRestaurantName] = useState("");
  const [isSendNotification, setIsSendNotification] = useState("");
  const [customMessageNotification, setCustomMessageNotification] =
    useState("");

  // Others informations
  const [preOrderType, setPreOrderType] = useState("ALL");
  const [isStripe, setIsStrip] = useState(false);
  const [isKhalti, setIsKhalti] = useState(false);
  const [maxValidRadius, setMaxValidRadius] = useState(null);
  const [isTakeAwayAllowed, setIsTakeAwayAllowed] = useState(false);
  const [isDeliveryAllowed, setIsDeliveryAllowed] = useState(false);
  const [takeAwayStallsList, settakeAwayStallsList] = useState([
    {
      stallName: "",
      stallLocation: "",
      stallLongitude: "",
      stallLatitude: "",
    },
  ]);

  console.log("sdfdf", { isKhalti, isStripe });

  useEffect(() => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", image);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_FAKE}/restaurant/v1/api/admin/add-delivery-user/image`,
      // url: "https://app.gurugfood.com:8443/restaurant/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setImageUrl(response.data.imageUrl);
      })
      .catch((error) => {
        console.log("eroor");
      });
  }, [image]);

  const handlelatLong2 = (myvalue) => {
    setLatitude2(myvalue.lat);
    setLongitude2(myvalue.lng);
    console.log("my value", myvalue);
  };
  const handleAddress2 = (address) => {
    setStallLocation(address);
    console.log("The location2", address);
  };

  const getLocatDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };

  useEffect(() => {
    let a = dayjs(preOrderStartDate)
      .format("YYYY-MM-DD hh:mm:ss")
      .split(" ")[0];
    let b = new Date(preOrderStartDate).toUTCString().split(" ")[4];
    let c = `${a} ${b}`;
    setPreOrderStartDateFinal(
      moment(preOrderStartDate).utc().format("YYYY-MM-DD HH:mm:ss")
    );
  }, [preOrderStartDate]);
  useEffect(() => {
    let a = dayjs(preOrderEndDate).format("YYYY-MM-DD hh:mm:ss").split(" ")[0];
    let b = new Date(preOrderEndDate).toUTCString().split(" ")[4];
    let c = `${a} ${b}`;
    setPreOrderEndDateFinal(
      moment(preOrderEndDate).utc().format("YYYY-MM-DD HH:mm:ss")
    );
  }, [preOrderEndDate]);
  useEffect(() => {
    let a = dayjs(deliveryStartDate)
      .format("YYYY-MM-DD hh:mm:ss")
      .split(" ")[0];
    let b = new Date(deliveryStartDate).toUTCString().split(" ")[4];
    let c = `${a} ${b}`;
    setDeliveryStartDateFinal(
      moment(deliveryStartDate).utc().format("YYYY-MM-DD HH:mm:ss")
    );
  }, [deliveryStartDate]);
  useEffect(() => {
    let a = dayjs(deliveryEndDate).format("YYYY-MM-DD hh:mm:ss").split(" ")[0];
    let b = new Date(deliveryEndDate).toUTCString().split(" ")[4];
    let c = `${a} ${b}`;
    setDeliveryEndDateFinal(
      moment(deliveryEndDate).utc().format("YYYY-MM-DD HH:mm:ss")
    );
  }, [deliveryEndDate]);

  const clearFileInputRef = useRef();

  const handlelatLong = (myvalue) => {
    setLatitude(myvalue.lat);
    setLongitude(myvalue.lng);
  };
  const handleAddress = (address) => {
    setDeliveryValidLocation(address);
  };
  const handleAddPreOrder = (e) => {
    e.preventDefault();
    if (!planName) {
      toast.error("Enter plan name first");
    } else if (!planTitle) {
      toast.error("Enter plan title first");
    } else if (!planDescription) {
      toast.error("Enter plan description first");
    } else if (!preOrderStartDate) {
      toast.error("Enter pre order start date first");
    } else if (!preOrderEndDate) {
      toast.error("Enter pre order end Date first");
    } else if (!planPrice) {
      toast.error("Enter plan price first");
    } else if (!planPrice) {
      toast.error("Enter plan price first");
    } else if (planPrice < 0) {
      toast.error("Plan price should be greater than 0");
    } else if (!planContactName) {
      toast.error("Enter plan contact person name first");
    } else if (!planContactPhone) {
      toast.error("Enter plan contact person phone number first");
    } else if (planContactPhone.length < 9) {
      toast.error("Contact number is less than 9 digit.");
    } else if (planContactPhone.length > 10) {
      toast.error("Contact number is greater than 10 digit.");
    } else if (!planContactEmail) {
      toast.error("Enter plan contact person email first");
    } else if (!deliveryStartDate) {
      toast.error("Enter delivery start date first");
    } else if (!deliveryEndDate) {
      toast.error("Enter delivery end date first");
    } else if (!deliveryValidLocation) {
      toast.error("Enter delivery valid location first");
    } else if (!validRadius) {
      toast.error("Enter valid radius first");
    } else if (validRadius < 0) {
      toast.error("Valid radius should be greater than 0");
    } else if (!maxRequestCount) {
      toast.error("Enter maximum request count first");
    } else if (!minimumRequestCount) {
      toast.error("Enter minimum request count first");
    } else if (isTakeAwayAllowed === false && isDeliveryAllowed === false) {
      toast.error("Preorder should be either pickup (takeaway) or delivery");
    } else if (
      (isTakeAwayAllowed && stallDetails[0].stallLocation === "") ||
      stallDetails[0].stallLocation === null ||
      stallDetails[0].stallLocation === undefined
    ) {
      toast.error("Enter stall location properly");
    } else {
      dispatch({
        type: "EDIT_PRE_ORDER_REQUEST",
        payload: {
          preOrderId: editinPreOrderItem?.planId,
          planName: planName,
          description: planDescription,
          planImage: imageUrl,
          planPrice: planPrice,
          preOrderStartDate: preOrderStartDateFinal,
          preOrderEndDate: preOrderEndDateFinal,
          deliveryStartDate: deliveryStartDateFinal,
          deliveryEndDate: deliveryEndDateFinal,
          planContactPersonEmail: planContactEmail,
          planContactNumber: planContactPhone,
          planContactPersonFullName: planContactName,
          planTitle: planTitle,
          maxValidRadius: maxValidRadius,
          isGlobalDelivery: isGlobalDelivery,
          deliveryValidLocation: deliveryValidLocation,
          deliveryValidLongitude: longitude,
          deliveryValidLatitude: latitude,
          validRadius: validRadius,
          validRadius:
            isTakeAwayAllowed && !isDeliveryAllowed ? 0 : validRadius,
          maxValidRadius:
            isTakeAwayAllowed && !isDeliveryAllowed ? 1 : maxValidRadius,
          maxRequestCount: maxRequestCount,
          minimumRequestCount: minimumRequestCount,
          preOrderType: preOrderType,
          restaurantId: restaurantId,
          sendNotification: isSendNotification,
          customNotificationMessage: isSendNotification
            ? customMessageNotification
            : "",
          orderType:
            isDeliveryAllowed === true && isTakeAwayAllowed === true
              ? "both"
              : isDeliveryAllowed === true && isTakeAwayAllowed === false
              ? "delivery"
              : isDeliveryAllowed === false && isTakeAwayAllowed === true
              ? "pickup"
              : null,
          deliveryPricePerKmAfterValidRadius:
            deliveryPricePerKmAfterValidRadius,
          allowedPaymentMethod:
            isKhalti & isStripe
              ? [
                  {
                    allowedMethod: "STRIPE",
                  },
                  {
                    allowedMethod: "KHALTI",
                  },
                ]
              : isKhalti
              ? [
                  {
                    allowedMethod: "KHALTI",
                  },
                ]
              : [
                  {
                    allowedMethod: "STRIPE",
                  },
                ],
          isTakeAwayAllowed: isTakeAwayAllowed,
          takeAwayStallsList: [...stallDetails],
        },
      });
    }
  };
  // {
  //   stallLocation: stallLocation,
  //   stallLongitude: longitude2,
  //   stallLatitude: latitude2,
  //   stallName: stallName,
  // },

  useEffect(() => {
    setImageUrl(editinPreOrderItem?.planImage);
    setPlanName(editinPreOrderItem?.planName);
    setPlanDescription(editinPreOrderItem?.description);
    setPlanTitle(editinPreOrderItem?.planTitle);
    setPlanContactName(editinPreOrderItem?.planContactPersonFullName);
    setPlanContactPhone(editinPreOrderItem?.planContactNumber);
    setPlanContactEmail(editinPreOrderItem?.planContactPersonEmail);

    setPreOrderStartDate(
      new Date(getLocatDateTime(editinPreOrderItem?.preOrderStartDate))
    );
    setPreOrderEndDate(
      new Date(getLocatDateTime(editinPreOrderItem?.preOrderEndDate))
    );
    setDeliveryStartDate(
      new Date(getLocatDateTime(editinPreOrderItem?.deliveryStartDate))
    );
    setDeliveryEndDate(
      new Date(getLocatDateTime(editinPreOrderItem?.deliveryEndDate))
    );

    setLatitude(editinPreOrderItem?.deliveryValidLatitude);
    setLongitude(editinPreOrderItem?.deliveryValidLongitude);
    setMaxValidRadius(editinPreOrderItem?.maxValidRadius);
    setIsSendNotification(editinPreOrderItem.sendNotification);
    setCustomMessageNotification(editinPreOrderItem.customNotificationMessage);
    setIsTakeAwayAllowed(editinPreOrderItem?.isTakeAwayAllowed);
    setIsDeliveryAllowed((prevData) => {
      if (
        editinPreOrderItem?.orderType === "delivery" ||
        editinPreOrderItem?.orderType === "DElIVERY" ||
        editinPreOrderItem?.orderType === "both" ||
        editinPreOrderItem?.orderType === "BOTH"
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (editinPreOrderItem?.allowedPaymentMethod?.length === 1) {
      if (
        editinPreOrderItem?.allowedPaymentMethod[0]?.allowedMethod === "KHALTI"
      ) {
        setIsKhalti(true);
        setIsStrip(false);
      } else {
        setIsStrip(true);
        setIsKhalti(false);
      }
    } else if (editinPreOrderItem?.allowedPaymentMethod?.length === 2) {
      setIsKhalti(true);
      setIsStrip(true);
    }

    setPlanPrice(editinPreOrderItem?.planPrice);
    setDeliveryValidLocation(editinPreOrderItem?.deliveryValidLocation);
    setValidRadius(editinPreOrderItem?.validRadius);
    setMaxRequestCount(editinPreOrderItem?.maxRequestCount);
    setMinimumRequestCount(editinPreOrderItem?.minimumRequestCount);
    setIsGlobalDelivery(editinPreOrderItem?.isGlobalDelivery);
    setMyRestaurantName(editinPreOrderItem?.assignedRestaurantName);
    setRestaurantId(editinPreOrderItem?.assignedRestaurantId);
    setDeliveryPricePerKmAfterValidRadius(
      editinPreOrderItem?.deliveryPricePerKmAfterValidRadius
    );
    settakeAwayStallsList(editinPreOrderItem?.takeAwayStallsList);
    if (editinPreOrderItem?.takeAwayStallsList?.length > 0) {
      setStallDetails(
        editinPreOrderItem?.takeAwayStallsList?.length !== 0
          ? [...editinPreOrderItem?.takeAwayStallsList]
          : [
              {
                stallLocation: "",
                stallLongitude: "",
                stallLatitude: "",
                stallName: "",
              },
            ]
      );
      setStallName(editinPreOrderItem?.takeAwayStallsList[0].stallName);
      setStallLocation(editinPreOrderItem?.takeAwayStallsList[0].stallLocation);
    }
  }, [editinPreOrderItem]);

  useEffect(() => {
    if (isEnabledUser === "b") {
      setPlanName(null);
      setPlanTitle(null);
      setPlanDescription(null);
    }
  }, [isEnabledUser]);

  useEffect(() => {
    let operatingLocation = null;
    if (myRestaurantName?.length > 2) {
      dispatch({
        type: "FETCH_RESTAURANT_LIST_REQUEST",
        payload: {
          keyWord: myRestaurantName,
          postsPerPage,
          currentPage,
          operatingLocation,
        },
      });
    }
  }, [myRestaurantName]);
  const onChange1 = (e) => {
    setIsSendNotification(e.target.value);
  };
  const [stallDetails, setStallDetails] = useState([
    {
      stallLocation: "",
      stallLongitude: "",
      stallLatitude: "",
      stallName: "",
    },
  ]);
  const removeStall = (index) => {
    const list = [...stallDetails];
    list.splice(index, 1);
    setStallDetails(list);
  };
  const addStall = () => {
    setStallDetails([
      ...stallDetails,
      {
        stallLocation: "",
        stallLongitude: "",
        stallLatitude: "",
        stallName: "",
      },
    ]);
  };
  const handleStallLocationChange = (e, index) => {
    console.log(e, index, "from handle stall location");

    const list = [...stallDetails];
    list[index].stallLocation = e;
    setStallDetails(list);
  };
  const handleStallLatitudeLongitudeChange = (e, index) => {
    console.log(e, index, "from handle stall longitude and latitude");
    const list = [...stallDetails];
    list[index].stallLatitude = e.lat;
    list[index].stallLongitude = e.lng;
    setStallDetails(list);
  };

  const handleStallNameChange = (e, index) => {
    console.log(e, index, "from handle stall name");
    const list = [...stallDetails];
    list[index].stallName = e;
    setStallDetails(list);
  };

  return (
    <div>
      <div className="Modal--heading">Update Pre Order Plan</div>
      <div className="Modal--form">
        <form>
          {/* <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Pre Order Image*: </label>
                            <input type="file" accept='image/*' disabled ref={clearFileInputRef} onChange={(e) => setImage(e.target.files[0])} /></p>
                    </div> */}
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Plan Name*: </label>
              <input
                type="text"
                placeholder="Plan Name"
                value={planName}
                onChange={(e) => setPlanName(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Plan Title*: </label>
              <input
                type="text"
                placeholder="Plan Title"
                value={planTitle}
                onChange={(e) => setPlanTitle(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Description*: </label>
              <textarea
                style={{ padding: "10px", minHeight: "50px" }}
                type="text"
                placeholder="Plan Description"
                value={planDescription}
                onChange={(e) => setPlanDescription(e.target.value)}
              ></textarea>
            </p>
          </div>

          <div className="Modal--form--title">
            <p className="addOrderStartDate">
              <label htmlFor="added_by">Pre Order Start Date*: </label>
              <DatePicker
                selected={preOrderStartDate}
                onChange={(date) => setPreOrderStartDate(date)}
                // disabled
                showTimeSelect
                dateFormat="yyyy-MM-dd h:mm aa"
                placeholderText="yyyy-mm-dd h:mm AM/PM"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p className="addOrderStartDate">
              <label htmlFor="added_by">Pre Order End Date*: </label>
              <DatePicker
                selected={preOrderEndDate}
                onChange={(date) => setPreOrderEndDate(date)}
                showTimeSelect
                minDate={preOrderStartDate}
                // excludeTimes={[
                //     setHours(setMinutes(new Date(), 0), 17),
                //     setHours(setMinutes(new Date(), 30), 18),
                //     setHours(setMinutes(new Date(), 30), 19),
                //     setHours(setMinutes(new Date(), 30), 17),
                // ]}
                dateFormat="yyyy-MM-dd h:mm aa"
                placeholderText="yyyy-mm-dd h:mm AM/PM"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Plan Price*: </label>
              <input
                type="number"
                placeholder="Plan Price"
                value={planPrice}
                onChange={(e) => setPlanPrice(e.target.value)}
                disabled
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Plan Contact Person Name*: </label>
              <input
                type="text"
                placeholder="Plan Contact Person Name"
                value={planContactName}
                onChange={(e) => setPlanContactName(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Plan Contact Person Phone*: </label>
              <input
                type="number"
                placeholder="Plan Contact Person Phone"
                value={planContactPhone}
                onChange={(e) => setPlanContactPhone(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Plan Contact Person Email*: </label>
              <input
                type="text"
                placeholder="Plan Contact Person Email"
                value={planContactEmail}
                onChange={(e) => setPlanContactEmail(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p className="addOrderStartDate">
              <label htmlFor="added_by">Delivery Start Date*: </label>
              <DatePicker
                selected={deliveryStartDate}
                onChange={(date) => setDeliveryStartDate(date)}
                showTimeSelect
                // disabled
                // excludeTimes={[
                //     setHours(setMinutes(new Date(), 0), 17),
                //     setHours(setMinutes(new Date(), 30), 18),
                //     setHours(setMinutes(new Date(), 30), 19),
                //     setHours(setMinutes(new Date(), 30), 17),
                // ]}
                dateFormat="yyyy-MM-dd h:mm aa"
                placeholderText="yyyy-mm-dd h:mm AM/PM"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p className="addOrderStartDate">
              <label htmlFor="added_by">Delivery End Date*: </label>
              <DatePicker
                selected={deliveryEndDate}
                onChange={(date) => setDeliveryEndDate(date)}
                showTimeSelect
                minDate={deliveryStartDate}
                // excludeTimes={[
                //     setHours(setMinutes(new Date(), 0), 17),
                //     setHours(setMinutes(new Date(), 30), 18),
                //     setHours(setMinutes(new Date(), 30), 19),
                //     setHours(setMinutes(new Date(), 30), 17),
                // ]}
                dateFormat="yyyy-MM-dd h:mm aa"
                placeholderText="yyyy-mm-dd h:mm AM/PM"
              />
            </p>
          </div>
          <div className="Modal--form--checkbox">
            <p>
              <input
                type="checkbox"
                name="activStatus"
                checked={isDeliveryAllowed}
                value={isDeliveryAllowed}
                onChange={(e) => setIsDeliveryAllowed(e.target.checked)}
              />
              <label htmlFor="added_by">Is Delivery </label>
            </p>
          </div>
          {isDeliveryAllowed && (
            <>
              <div className="Modal--form--title">
                <p style={{ display: "flex", alignItems: "flex-end" }}>
                  <label htmlFor="title">Delivery Valid Location: </label>
                  <GetValidLocation
                    defaultAddress={deliveryValidLocation}
                    handlelatLong={handlelatLong}
                    handleAddress={handleAddress}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="added_by">Valid Radius in KM*: </label>
                  <input
                    type="number"
                    placeholder="Valid Radius in KM"
                    value={validRadius}
                    onChange={(e) => setValidRadius(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="added_by">Max Valid Radius in KM*: </label>
                  <input
                    type="number"
                    placeholder="Max Valid Radius in KM"
                    value={maxValidRadius}
                    onChange={(e) => setMaxValidRadius(e.target.value)}
                  />
                </p>
              </div>
              <div className="Modal--form--title">
                <p>
                  <label htmlFor="added_by">
                    Delivery Charge After Valid Radius Per KM*:{" "}
                  </label>
                  <input
                    type="number"
                    placeholder="Delivery Charge After Valid Radius Per KM"
                    value={deliveryPricePerKmAfterValidRadius}
                    onChange={(e) =>
                      setDeliveryPricePerKmAfterValidRadius(e.target.value)
                    }
                  />
                </p>
              </div>
            </>
          )}
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Minimum Request Count*: </label>
              <input
                type="number"
                min={0}
                max={maxRequestCount}
                placeholder="Minimum Request Count"
                value={minimumRequestCount}
                onChange={(e) => setMinimumRequestCount(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Maximum Request Count*: </label>
              <input
                type="number"
                min={minimumRequestCount}
                max={1000}
                placeholder="Maximum Request Count"
                value={maxRequestCount}
                onChange={(e) => setMaxRequestCount(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Restaurant Name: </label>
              <input
                type="text"
                placeholder="Search Restaurant Name"
                value={myRestaurantName}
                onChange={(e) => setMyRestaurantName(e.target.value)}
              />
            </p>
          </div>
          <div className="showRestaurantOption--addpreorder">
            <div className="restaurant--lists ">
              {searchRestaurantList?.data?.map((item) => (
                <div
                  className={`${restaurantId === item.id && "active"}`}
                  onClick={() => setRestaurantId(item.id)}
                >
                  <p>{item.restaurantName}</p>
                  <p>
                    <GrLocation />
                    {item.location}
                  </p>
                  <p>
                    <FaCheck />
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is Send Notification ?: </label>
              <Radio.Group
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onChange1}
                value={isSendNotification}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>
          {isSendNotification && (
            <>
              <div className="Modal--form--title">
                <p>
                  <label style={{ marginTop: "-4rem" }}>
                    Notification Message :{" "}
                  </label>
                  <textarea
                    type="text"
                    placeholder="Custom Message"
                    value={customMessageNotification}
                    onChange={(e) =>
                      setCustomMessageNotification(e.target.value)
                    }
                    style={{ height: "60px" }}
                  />
                </p>
              </div>
              <p style={{ textAlign: "center", color: "red" }}>
                Default Message will be sent if left empty !
              </p>
            </>
          )}

          <div className="Modal--form--checkbox">
            <p>
              <input
                type="checkbox"
                name="activStatus"
                checked={isGlobalDelivery}
                value={isGlobalDelivery}
                onChange={(e) => setIsGlobalDelivery(e.target.checked)}
              />
              <label htmlFor="added_by">Is Global Delivery </label>
            </p>
          </div>

          <h3 style={{ marginLeft: "5vw" }}>Other information : </h3>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">PreOrder Type*: </label>
              <select
                value={preOrderType}
                onChange={(e) => setPreOrderType(e.target.value)}
              >
                <option value="ALL">All</option>
                <option value="COLLEGE">College</option>
              </select>
            </p>
          </div>
          <div className="add-preorder--paymentMethod">
            <p>Payment Methods*: </p>
            <div>
              <p>
                <input
                  type="checkbox"
                  value="Stripe"
                  checked={isStripe}
                  onChange={(e) => setIsStrip(e.target.checked)}
                />{" "}
                <label>Stripe</label>
              </p>
              <p>
                {" "}
                <input
                  type="checkbox"
                  value="Khalti"
                  checked={isKhalti}
                  onChange={(e) => setIsKhalti(e.target.checked)}
                />{" "}
                <label>Khalti</label>
              </p>
            </div>
          </div>
          <div className="Modal--form--checkbox">
            <p>
              <input
                type="checkbox"
                name="activStatus"
                checked={isTakeAwayAllowed}
                value={isTakeAwayAllowed}
                onChange={(e) => setIsTakeAwayAllowed(e.target.checked)}
              />
              <label htmlFor="added_by">Is PickUp </label>
            </p>
          </div>
          {isTakeAwayAllowed && (
            <div>
              <div
                style={{ display: "flex", gap: "1rem", alignItems: "center" }}
              >
                <h3 style={{ marginLeft: "5vw" }}>Add Stall Details : </h3>
                <Button
                  style={{ backgroundColor: "#3e445e", color: "white" }}
                  onClick={addStall}
                >
                  Add
                </Button>
              </div>
              {stallDetails?.map((stall, index) => (
                <Fragment key={index}>
                  <div className="Modal--form--title">
                    <p>
                      <label htmlFor="added_by">Stall Number*: </label>
                      <input
                        type="number"
                        placeholder="Stall Number"
                        value={stallDetails[index].stallName}
                        onChange={(e) => {
                          handleStallNameChange(e.target.value, index);
                          // setStallName(e.target.value);
                        }}
                      />
                    </p>
                  </div>
                  <div className="Modal--form--title">
                    <p style={{ display: "flex", alignItems: "flex-end" }}>
                      <label htmlFor="title">Stall Location: </label>
                      <GetValidLocation
                        handlelatLong={(val) =>
                          handleStallLatitudeLongitudeChange(val, index)
                        }
                        handleAddress={(val) =>
                          handleStallLocationChange(val, index)
                        }
                        defaultAddress={stallDetails[index].stallLocation}
                        ref={(ref) => (innerClassRef.current = ref)}
                      />
                    </p>
                  </div>
                </Fragment>
              ))}
              {stallDetails.length > 0 && (
                <div className="remove--timeSlot__btn">
                  <div
                    onClick={() => {
                      console.log("innerClassRef", innerClassRef);
                      // innerClassRef?.current.handleRemove();
                      setStallDetails([
                        {
                          stallLocation: "",
                          stallLongitude: "",
                          stallLatitude: "",
                          stallName: "",
                        },
                      ]);
                    }}
                    style={{
                      color: "white",
                      whiteSpace: "nowrap",
                      width: "90px",
                    }}
                  >
                    Remove All
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleAddPreOrder}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(EditPreOrder);

//add stall
// {stallDetails?.map((stall, index) => {
//   console.log("location");
//   return (
//     <Fragment key={stall.stallName}>
//       <div className="Modal--form--title">
//         <p>
//           <label htmlFor="added_by">Stall Number*: </label>
//           <input
//             type="number"
//             placeholder="Stall Number"
//             value={stall.stallName}
//             onChange={(e) => setStallName(e.target.value)}
//           />
//         </p>
//       </div>
//       <div className="Modal--form--title">
//         <p style={{ display: "flex", alignItems: "flex-end" }}>
//           <label htmlFor="title">Stall Location: </label>
//           <GetValidLocation
//             handlelatLong={handlelatLong2}
//             handleAddress={handleAddress2}
//             defaultAddress={stall?.stallLocation}
//           />
//         </p>
//       </div>
//     </Fragment>
//   );
// })}
