import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../../FrontendHelper";
import { Checkbox, Col, Row, Radio, DatePicker } from "antd";
import moment from "moment";
import LoadingComponent from "../../LoadingComponent";
import { toast } from "react-toastify";

const mapStateToProps = (store) => {
  const {
    buttonText,
    isEnabledUsers,
    searchUsersList,
    isLoadingOnLogin,
    isOperationSuccessful,
    merchantCategories,
  } = store.mainState;
  return {
    buttonText,
    isEnabledUsers,
    searchUsersList,
    isLoadingOnLogin,
    isOperationSuccessful,
    merchantCategories,
  };
};

function AddSubCategory({
  buttonText,
  isEnabledUsers,
  category,
  setCategory,
  searchUsersList,
  isLoadingOnLogin,
  isOperationSuccessful,
  restaurantId,
  merchantCategories,
}) {
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryImage, setSubCategoryImage] = useState("");
  const dispatch = useDispatch();
  const clearFileInputRef = useRef(null);

  const handleAddCategory = (e) => {
    e.preventDefault();
    if (!category || !subCategoryName || !subCategoryImage) {
      return toast.error("Please fill all the fields !!");
    }
    const merchantFormData = new FormData();
    merchantFormData.append("categoryId", category);
    merchantFormData.append("categoryName", subCategoryName);
    merchantFormData.append("image", subCategoryImage);

    dispatch({
      type: "ADD_MERCHANT_SUB_CATEGORY_REQUEST",
      payload: {
        categoryId: category,
        formData: merchantFormData,
      },
    });
  };

  useEffect(() => {
    if (isEnabledUsers === "b") {
      setSubCategoryName("");
      clearFileInputRef.current.value = "";
    }
  }, [isEnabledUsers]);

  console.log("merchant subcategory", category);

  return (
    <div>
      <div className="Modal--heading">Add Sub-Category</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title"> Image : </label>
              <input
                ref={clearFileInputRef}
                type="file"
                onChange={(e) => setSubCategoryImage(e.target.files[0])}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <label htmlFor="title">Category: </label>
              <Radio.Group
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  display: "flex",
                }}
                onChange={(e) => {
                  console.log("category name", e.target.value);
                  setCategory(e.target.value);
                }}
                value={category}
              >
                {merchantCategories.data?.map((item) => {
                  return (
                    <Radio
                      value={item?.id}
                      style={{
                        margin: "0.2rem",
                        width: "100%",
                        // display: "inline-block",
                      }}
                    >
                      {item?.categoryName}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Sub Category Name: </label>
              <input
                type="text"
                value={subCategoryName}
                placeholder="Category Name"
                onChange={(e) => setSubCategoryName(e.target.value)}
              />
            </p>
          </div>

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleAddCategory}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddSubCategory);
