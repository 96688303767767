import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../FrontendHelper";
import axios from "axios";
import { Tooltip } from "antd";

function EditCampaign({ item }) {
  const dispatch = useDispatch();
  const clearFileInputRef = useRef();
  const { updateButtonText } = useSelector((state) => state.mainState);
  const [campaignImage, setCampaighImage] = useState(null);
  const [campaignName, setCampaignName] = useState("");
  const [campaignDescription, setDescription] = useState("");
  const [price, setPrice] = useState(null);
  const [isAddItem, setIsAddItem] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState([
    {
      id: null,
      itemName: "",
      quantity: 0,
      description: "",
      itemImage: "",
      imageFullPath: "",
    },
  ]);

  const handleItemValueChange = (index, event) => {
    let values = [...itemDetails];
    values[index][event.target.name] = event.target.value;
    setItemDetails(values);
  };
  //   const handleAddItem = () => {
  //     // setIsAddItem(true)
  //     setItemDetails([...itemDetails, {
  //       "id": null,
  //       "itemName": "",
  //       "quantity": 0,
  //       "description": "",
  //       "itemImage": ""
  //     },])
  // }
  // const handleRemoveItem = (index)=> {
  //   if(itemDetails.length >1) {
  //     let values = [...itemDetails]
  //     values.splice(index, 1)
  //     setItemDetails(values)
  //   }

  // }
  const handleItemImageChange = (index, event) => {
    setLoading(true);

    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", event.target.files[0]);

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_FAKE}/foocery/v1/api/admin/campaign/upload-image`,
      // url: "https://app.gurugfood.com:8443/restaurant/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        console.log("The respone after upload image", response);
        let values = [...itemDetails];
        values[index].imageFullPath = response.data?.imageFullPath;
        values[index].itemImage = response.data?.imageUrl;
        setItemDetails(values);
        setLoading(false);
      })
      .catch((error) => {
        console.log("The error after upload image", error);
        setLoading(false);
      });
    // console.log("the index, and event", {index,event})
  };
  const handleChampaignImageChange = (e) => {
    setLoading(true);

    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", e.target.files[0]);

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_FAKE}/foocery/v1/api/admin/campaign/upload-image`,
      // url: "https://app.gurugfood.com:8443/restaurant/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setCampaighImage(response.data?.imageUrl);
        setLoading(false);
      })
      .catch((error) => {
        console.log("The error after upload image", error);
        setLoading(false);
      });
    // console.log("the index, and event", {index,event})
  };

  const handleAddCampaign = (e) => {
    e.preventDefault();
    dispatch({
      type: "EDIT_CAMPAIGN_REQUEST",
      payload: {
        id: item.id,
        campaignName: campaignName,
        campaignImage: campaignImage?.replace(
          "https://restaurant-dev.s3-ap-south-1.amazonaws.com/",
          ""
        ),
        description: campaignDescription,
        price: Number(price),
        itemData: itemDetails,
      },
    });
  };

  useEffect(() => {
    if (item !== undefined && item !== null) {
      setCampaighImage(item.campaignImage);
      setCampaignName(item.campaignName);
      setPrice(item.price);
      setDescription(item.description);
      setItemDetails(item.itemData);
    }
  }, [item]);

  return (
    <div>
      <div className="Modal--heading">Edit Campaign</div>
      {/* <button onClick={handleAddItem}>Add Item</button> */}
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Image : </label>
              <input
                type="file"
                ref={clearFileInputRef}
                name="image"
                accept="image/*"
                onChange={(e) => handleChampaignImageChange(e)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Campaign Name: </label>
              <input
                type="text"
                value={campaignName}
                placeholder="Campaign Name"
                onChange={(e) => setCampaignName(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Campaign Description: </label>
              <input
                type="text"
                value={campaignDescription}
                placeholder="Campaign Description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Campaign Price: </label>
              <input
                type="text"
                value={price}
                placeholder="Campaign Price"
                onChange={(e) => setPrice(e.target.value)}
              />
            </p>
          </div>
          {itemDetails?.map((item, index) => {
            const originalImage = item.itemImage;
            const trimmedImage = item.itemImage?.replace(
              "https://restaurant-dev.s3-ap-south-1.amazonaws.com/",
              ""
            );
            itemDetails[index].itemImage = trimmedImage;
            itemDetails[index].imageFullPath = originalImage;

            console.log("originalImage from mapped", originalImage);
            console.log("trimmedImage from mapped", trimmedImage);
            console.log("item from mapped", item);
            console.log("itemDetails from mapped", itemDetails);
            return (
              <>
                {/* <div className="item__remove--button" key={index + 1} onClick={()=> handleRemoveItem(index)}>Remove</div> */}
                <div className="campaignItem" style={{ marginTop: "20px" }}>
                  <div className="campaignItem--container">
                    <div className="item--input" key={index + 1}>
                      <div className="Modal--form--title">
                        <p>
                          <label htmlFor="title">Item Name : </label>
                          <input
                            type="text"
                            value={item.itemName}
                            name="itemName"
                            placeholder="Item Name"
                            onChange={(event) =>
                              handleItemValueChange(index, event)
                            }
                          />
                        </p>
                      </div>
                      <div className="Modal--form--title">
                        <p>
                          <label htmlFor="title">Item Quantity: </label>
                          <input
                            type="text"
                            value={item.quantity}
                            name="quantity"
                            placeholder="Item Quantity"
                            onChange={(event) =>
                              handleItemValueChange(index, event)
                            }
                          />
                        </p>
                      </div>
                      <div className="Modal--form--title">
                        <p>
                          <label htmlFor="title">Item Description: </label>
                          <textarea
                            type="text"
                            value={item.description}
                            name="description"
                            placeholder="Item Description"
                            onChange={(event) =>
                              handleItemValueChange(index, event)
                            }
                          ></textarea>
                        </p>
                      </div>
                    </div>
                    <div className="item--image">
                      <Tooltip title="Click Here to uplaod another Image">
                        <div>
                          <img
                            src={`https://restaurant-dev.s3-ap-south-1.amazonaws.com/${item.itemImage}`}
                            alt=""
                          />
                          <input
                            type="file"
                            onChange={(event) =>
                              handleItemImageChange(index, event)
                            }
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleAddCampaign}
              disabled={loading}
            >
              {loading ? "Uploading..." : updateButtonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditCampaign;
