import React, { useState, useEffect, useRef } from "react";
import Layout from "../../Layout/Layout";
import { BiChevronLeft, BiChevronRight, BiEdit } from "react-icons/bi";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import { BsEyeFill, BsCheckCircle, BsLightning } from "react-icons/bs";
import { BsX } from "react-icons/bs";
import { VscListTree } from "react-icons/vsc";
import { CgMenuRound } from "react-icons/cg";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import UpdateRestaurant from "./UpdateRestaurant";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
import { toast } from "react-toastify";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import RestroDetails from "./RestroDetails";
import { Link } from "react-router-dom";

const mapStateToProps = (store) => {
  const {
    isLoadingOnLogin,
    merchantCategories,
    merchantSubCategories,
    restaurantData,
    isToogleSidebar,
    isEnabledUsers,
  } = store.mainState;
  return {
    isLoadingOnLogin,
    merchantCategories,
    merchantSubCategories,
    restaurantData,
    isToogleSidebar,
    isEnabledUsers,
  };
};

function Restaurant({
  isLoadingOnLogin,
  restaurantData,
  isToogleSidebar,
  isEnabledUsers,
  merchantCategories,
  merchantSubCategories,
}) {
  const [showSmModal, setShowSmModal] = useState(false);
  const [enableDisableItem, setEnableDisableItem] = useState({});
  const [updateRestaurantModal, setUpdateRestaurantModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [sendMailFlag, setSendMailFlag] = useState(false);
  // Delete restaurant
  const [editEmailModal, setEditEmailModal] = useState(false);
  const [editingEmail, setEditingEmail] = useState(null);
  const [editingEmailResId, setEditingEmailResId] = useState(null);
  // popular/remove popular restaurant modal
  const [showPopularSmModal, setShowPopularSmModal] = useState(false);
  const [popularResItem, setPopularResItem] = useState(null);
  // show restaurant details
  const [showRestroDetailModal, setShowRestroDetailModal] = useState(false);
  const [detailShowingItem, setDetailShowingItem] = useState(null);

  const [searchWord, setSearchWord] = useState("");
  const [showChooseAddResModal, setShowChooseAddRes] = useState(false);
  // Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [sortingParam, setSortingParam] = useState("id");

  //filter
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [isSupplier, setIsSupplier] = useState("");

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (showChooseAddResModal) {
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setShowChooseAddRes(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showChooseAddResModal]);

  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const dispatch = useDispatch();

  useEffect(() => {
    if (searchWord) {
      setTimeout(() => {
        dispatch({
          type: "SEARCH_RESNAME_REQUEST",
          payload: { searchWord },
        });
      }, 1000);
    }
  }, [searchWord]);
  useEffect(() => {
    dispatch({
      type: "FETCH_RESTAURANTS_REQUEST",
      payload: { sortingParam, currentPage, postsPerPage },
    });
  }, [currentPage, postsPerPage]);

  for (let i = 1; i <= restaurantData.totalPage; i++) {
    pageNumbers.push(i);
  }
  console.log("The isEnabledUsers", isEnabledUsers);
  useEffect(() => {
    if (isEnabledUsers === "b") {
      setTimeout(() => {
        setShowSmModal(false);
        setShowPopularSmModal(false);
        setEditEmailModal(false);
        setSendMailFlag(false);
        dispatch({
          type: "FETCH_RESTAURANTS_REQUEST",
          payload: { sortingParam, currentPage, postsPerPage },
        });
      }, 1000);
    }
  }, [isEnabledUsers]);

  const Token = getCookie("token");

  const str = () =>
    restaurantData.data !== undefined &&
    restaurantData.data.map((item) => (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>
          <img className="myLargeImage" src={item.imageUrl} alt="image" />
        </td>
        <td>{item.restaurantName}</td>
        <td>{item.contactPersonName}</td>
        <td>{item.contactPersonPhone}</td>
        <td>{item.location}</td>
        <td>
          {item.email}{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => {
              setEditEmailModal(true);
              setEditingEmail(item.email);
              setEditingEmailResId(item.id);
            }}
          >
            <BiEdit />
          </span>
        </td>
        <td>{item.commissionRate}</td>
        <td>
          <span
            style={{
              backgroundColor: "purple",
              borderRadius: "4px",
              color: "white",
            }}
          >
            {item.isPopular ? "Popular" : "Not Popular"}
          </span>
        </td>
        <td>
          <span
            style={{
              backgroundColor: "purple",
              borderRadius: "4px",
              color: "white",
            }}
          >
            {item.isVisibleForUsers ? "Yes" : "No"}
          </span>
        </td>
        <td>
          <span
            style={{
              backgroundColor: "green",
              borderRadius: "4px",
              color: "white",
            }}
          >
            {item.active ? "Active" : "Not Active"}
          </span>
        </td>

        <td>
          <Tippy
            content={<span>{item.active ? "De-activate" : "Activate"}</span>}
            placement="top"
          >
            <span>
              <BsCheckCircle
                className="action--eye__admin"
                onClick={() => {
                  setShowSmModal(true);
                  setEnableDisableItem(item);
                }}
              />{" "}
            </span>
          </Tippy>
          <Tippy content="Edit" placement="top">
            <span>
              <AiFillEdit
                className="edit--icon"
                onClick={() => {
                  setUpdateRestaurantModal(true);
                  setEditingItem(item);
                }}
              />{" "}
            </span>
          </Tippy>
          {item.isPopular ? (
            <Tippy content="Remove From Popular" placement="top">
              <span>
                <BsLightning
                  className="edit--icon"
                  onClick={() => {
                    setShowPopularSmModal(true);
                    setPopularResItem(item);
                  }}
                />{" "}
              </span>
            </Tippy>
          ) : (
            <Tippy content="Mark As Popular" placement="top">
              <span>
                <BsLightning
                  className="edit--icon"
                  onClick={() => {
                    setShowPopularSmModal(true);
                    setPopularResItem(item);
                  }}
                />{" "}
              </span>
            </Tippy>
          )}

          <Tippy content="View" placement="top">
            <span>
              <BsEyeFill
                className="action--edit__admin"
                onClick={() => {
                  setDetailShowingItem(item);
                  setShowRestroDetailModal(true);
                }}
              />{" "}
            </span>
          </Tippy>
          <Tippy content="Menu Category" placement="bottom">
            <Link to={`/restaurant-menu/${item.id}`}>
              <span>
                <CgMenuRound className="action--edit__admin" />{" "}
              </span>
            </Link>
          </Tippy>
          {/* <Tippy content="Menu Category" placement="bottom">
            <Link to="/restaurant-category/id">
              <span>
                <VscListTree className="action--edit__admin" />{" "}
              </span>
            </Link>
          </Tippy> */}
          {/* <MdDelete className="action--eye__admin" onClick={() => {
                    setShowDeleteModal(true);
                    setDeletingId(item.id)
                }} />  */}
        </td>
      </tr>
    ));

  return (
    <Layout>
      {/* {showChooseAddResModal && 
            <div className="choose-restaurant" >
                <div ref={wrapperRef}>
                <p onClick={()=> {
                    setShowChooseAddRes(false)
                    dispatch({ type: "ADD_RESTAURANT_MODAL" })
                }}>With Csv file</p>
                <p onClick={()=> {
                    setShowChooseAddRes(false)
                    dispatch({ type: "ADD_RES_WITHOUT_CSV_MODAL" })
                }}>Without Csv file</p>
                </div>
            </div>} */}
      <div>
        <div className="group_list">
          <h4>Manage Merchant/Supplier</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div
              className="user_groups--button_n_search--button"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div style={{ marginBottom: "0.6rem" }}>
                  <label
                    htmlFor=""
                    style={{
                      marginLeft: "10px",
                      fontSize: "18px",
                      fontFamily: "bold",
                      color: "#3e445e",
                      marginRight: "-10px",
                    }}
                  >
                    Category
                  </label>
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      console.log("This is all", e.target.value);
                      setCategory(e.target.value);
                      if (
                        e.target.value !== null &&
                        e.target.value !== undefined &&
                        e.target.value !== ""
                      ) {
                        dispatch({
                          type: "FETCH_MERCHANT_SUB_CATEGORY_REQUEST",
                          payload: {
                            categoryId: e.target.value,
                            postsPerPage: 1,
                            currentPage: 10,
                          },
                        });
                      }
                      if (
                        e.target.value === "" &&
                        subCategory === "" &&
                        isSupplier === ""
                      ) {
                        dispatch({
                          type: "FETCH_RESTAURANTS_REQUEST",
                          payload: { sortingParam, currentPage, postsPerPage },
                        });
                      } else {
                        dispatch({
                          type: "FILTER_MERCHANT_REQUEST",
                          payload: {
                            categoryId: e.target.value,
                            subCategoryId: subCategory,
                            isSupplier: isSupplier,
                            postsPerPage: postsPerPage,
                            currentPage: currentPage,
                          },
                        });
                      }
                    }}
                    style={{ width: "55px" }}
                  >
                    <option value="">All</option>
                    {merchantCategories?.data?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.categoryName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div style={{ marginBottom: "0.6rem" }}>
                  <label
                    htmlFor=""
                    style={{
                      marginLeft: "10px",
                      fontSize: "18px",
                      fontFamily: "bold",
                      color: "#3e445e",
                      marginRight: "-10px",
                    }}
                  >
                    Sub-Category
                  </label>
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      setSubCategory(e.target.value);
                      if (
                        e.target.value === "" &&
                        isSupplier === "" &&
                        category === ""
                      ) {
                        dispatch({
                          type: "FETCH_RESTAURANTS_REQUEST",
                          payload: { sortingParam, currentPage, postsPerPage },
                        });
                      } else {
                        dispatch({
                          type: "FILTER_MERCHANT_REQUEST",
                          payload: {
                            categoryId: category,
                            subCategoryId: e.target.value,
                            isSupplier: isSupplier,
                            postsPerPage: postsPerPage,
                            currentPage: currentPage,
                          },
                        });
                      }
                    }}
                    style={{ width: "55px" }}
                  >
                    <option value="">All</option>
                    {merchantSubCategories?.data?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.categoryName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="isSupplier"
                    style={{
                      marginLeft: "10px",
                      fontSize: "18px",
                      fontFamily: "bold",
                      color: "#3e445e",
                      marginRight: "-10px",
                    }}
                  >
                    Supplier
                  </label>
                  <select
                    name="isSupplier"
                    onChange={(e) => {
                      setIsSupplier(e.target.value);
                      if (
                        e.target.value === "" &&
                        subCategory === "" &&
                        category === ""
                      ) {
                        dispatch({
                          type: "FETCH_RESTAURANTS_REQUEST",
                          payload: { sortingParam, currentPage, postsPerPage },
                        });
                      } else {
                        dispatch({
                          type: "FILTER_MERCHANT_REQUEST",
                          payload: {
                            categoryId: category,
                            subCategoryId: subCategory,
                            isSupplier: e.target.value,
                            postsPerPage: postsPerPage,
                            currentPage: currentPage,
                          },
                        });
                      }
                    }}
                    style={{ width: "55px" }}
                  >
                    <option value={""}>All</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div
              className="user_groups--button_n_search--button"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <h4>Merchant/Supplier</h4>
              <button
                onClick={() => {
                  dispatch({ type: "ADD_RES_WITHOUT_CSV_MODAL" });
                }}
              >
                Add <i className="bi bi-plus c-plus" />
              </button>
              <div>
                <label
                  htmlFor="pageSize"
                  style={{
                    marginLeft: "10px",
                    fontSize: "18px",
                    fontFamily: "bold",
                    color: "#3e445e",
                    marginRight: "-10px",
                  }}
                >
                  Per Page
                </label>
                <select
                  name="pageSize"
                  onChange={(e) => setPostsPerPage(e.target.value)}
                  style={{ width: "55px" }}
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              </div>
            </div>
            <div className="user_groups--button_n_search--search">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <input
                  type="text"
                  value={searchWord}
                  placeholder="Search"
                  onChange={(e) => setSearchWord(e.target.value)}
                />
              </form>
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container" style={{ overflow: "auto" }}>
            <table className="table_x">
              <thead>
                <tr>
                  <th className="user--id">ID</th>
                  <th className="user--name">Image</th>
                  <th className="user--name">Merchant's Name</th>
                  <th className="user--email">Contact Person Name</th>
                  <th className="user--mobile">Contact Person Phone No</th>
                  <th className="user--email">Location</th>
                  <th className="user--email">Email</th>
                  <th className="user--status">Commission Rate</th>
                  <th className="user--deleted">Popular</th>
                  <th className="user--deleted">Is Visible For User</th>

                  <th className="user--deleted">Active Status</th>

                  <th className="user--actions" style={{ width: "12%" }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>{str()}</tbody>
            </table>
            <div
              className={`${
                updateRestaurantModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {editingItem !== null && (
                  <UpdateRestaurant item={editingItem} />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setUpdateRestaurantModal(!updateRestaurantModal);
                        dispatch({
                          type: "FETCH_RESTAURANTS_REQUEST",
                          payload: { sortingParam, currentPage, postsPerPage },
                        });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Edit</p>
                  </div>
                </div>
              </div>
            </div>
            {/* show restaurant Details */}
            <div
              className={`${
                showRestroDetailModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {detailShowingItem !== null && (
                  <RestroDetails item={detailShowingItem} />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span onClick={() => setShowRestroDetailModal(false)}>
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>
            {/* for Enable/Disable Security Question  */}

            <div
              className={`${
                showSmModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading"></div>
                <>
                  <div className="delete--admin--container__body">
                    {enableDisableItem.active ? (
                      <h1>Are you sure to Disable this ??</h1>
                    ) : (
                      <h1>Are you sure to Enable this ??</h1>
                    )}
                    <div>
                      {isEnabledUsers === "a" && (
                        <div className="deleting--something">
                          {" "}
                          <p>Processing...</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "b" && (
                        <div className="deleting--something">
                          {" "}
                          <TiTick className="success--icon" /> <p>Successful</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "c" && (
                        <div className="deleting--something">
                          {" "}
                          <GiSkullCrossedBones
                            className="success--icon"
                            style={{ fontSize: "30px" }}
                          />{" "}
                          <p>Try Again</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button onClick={() => setShowSmModal(!showSmModal)}>
                      Cancel
                    </button>
                    {enableDisableItem.active ? (
                      <button
                        onClick={() => {
                          let enableDisableId = enableDisableItem.id;
                          dispatch({
                            type: "DEACTIVATE_RESTAURANT_REQUEST",
                            payload: {
                              id: enableDisableId,
                            },
                          });
                        }}
                      >
                        Ok
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          let enableDisableId = enableDisableItem.id;
                          dispatch({
                            type: "ACTIVATE_RESTAURANT_REQUEST",
                            payload: {
                              id: enableDisableId,
                            },
                          });
                        }}
                      >
                        Ok
                      </button>
                    )}
                  </div>
                </>
              </div>
            </div>
            {/* mark/unmark popular  */}

            {popularResItem !== null && (
              <div
                className={`${
                  showPopularSmModal ? "delete--admin" : "delete--admin__hidden"
                }`}
              >
                <div className="delete--admin--container">
                  <div className="delete--admin--container__heading"></div>
                  <>
                    <div className="delete--admin--container__body">
                      {popularResItem.isPopular ? (
                        <h1>Are you sure to remove from popular ??</h1>
                      ) : (
                        <h1>Are you sure to mark as Popular ??</h1>
                      )}

                      <div>
                        {isEnabledUsers === "a" && (
                          <div className="deleting--something">
                            {" "}
                            <p>Processing...</p>
                          </div>
                        )}
                      </div>
                      <div>
                        {" "}
                        {isEnabledUsers === "b" && (
                          <div className="deleting--something">
                            {" "}
                            <TiTick className="success--icon" />{" "}
                            <p>Successful</p>
                          </div>
                        )}
                      </div>
                      <div>
                        {" "}
                        {isEnabledUsers === "c" && (
                          <div className="deleting--something">
                            {" "}
                            <GiSkullCrossedBones
                              className="success--icon"
                              style={{ fontSize: "30px" }}
                            />{" "}
                            <p>Try Again</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="delete--admin--container__footer">
                      <button onClick={() => setShowPopularSmModal(false)}>
                        Cancel
                      </button>
                      {popularResItem.isPopular ? (
                        <button
                          onClick={() => {
                            let id = popularResItem.id;
                            dispatch({
                              type: "UN_MARK_RESTAURANT_AS_POPULAR_REQUEST",
                              payload: {
                                id,
                              },
                            });
                          }}
                        >
                          Ok
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            let id = popularResItem.id;
                            dispatch({
                              type: "MARK_RESTAURANT_POPULAR_REQUEST",
                              payload: {
                                id,
                              },
                            });
                          }}
                        >
                          Ok
                        </button>
                      )}
                    </div>
                  </>
                </div>
              </div>
            )}

            {/* Edit restaurant email address */}
            {editingEmail !== null && (
              <div
                className={`${
                  editEmailModal ? "delete--admin" : "delete--admin__hidden"
                }`}
              >
                <div className="delete--admin--container">
                  <div className="delete--admin--container__heading"></div>
                  <>
                    <div className="delete--admin--container__body">
                      <div className="update_restaurant__email">
                        <p>Email : </p>
                        <input
                          type="email"
                          value={editingEmail}
                          onChange={(e) => setEditingEmail(e.target.value)}
                        />
                        <p>
                          <input
                            type="checkbox"
                            value={sendMailFlag}
                            onChange={(e) => setSendMailFlag(e.target.checked)}
                          />{" "}
                          Send Mail
                        </p>
                      </div>

                      <div>
                        {isEnabledUsers === "a" && (
                          <div className="deleting--something">
                            {" "}
                            <p>Processing...</p>
                          </div>
                        )}
                      </div>
                      <div>
                        {" "}
                        {isEnabledUsers === "b" && (
                          <div className="deleting--something">
                            {" "}
                            <TiTick className="success--icon" />{" "}
                            <p>Successful</p>
                          </div>
                        )}
                      </div>
                      <div>
                        {" "}
                        {isEnabledUsers === "c" && (
                          <div className="deleting--something">
                            {" "}
                            <GiSkullCrossedBones
                              className="success--icon"
                              style={{ fontSize: "30px" }}
                            />{" "}
                            <p>Try Again</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="delete--admin--container__footer">
                      <button onClick={() => setEditEmailModal(false)}>
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          dispatch({
                            type: "EDIT_RESTAURANT_EMAIL_REQUEST",
                            payload: {
                              sendMailFlag: sendMailFlag,
                              email: editingEmail,
                              id: editingEmailResId,
                            },
                          });
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </>
                </div>
              </div>
            )}
            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > restaurantData.totalData
                  ? restaurantData.totalData
                  : indexOfLastPost}{" "}
                of {restaurantData.totalData} entries
              </p>
              <nav aria-label="...">
                <ul
                  className="pagination"
                  style={{ maxWidth: "400px", overflowX: "auto" }}
                >
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(Restaurant);
