import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";

const mapStateToProps = (store) => {
  const { buttonText, isEnabledUser } = store.mainState;
  return { buttonText, isEnabledUser };
};

function AddOperatingLocation({ buttonText, isEnabledUser }) {
  const dispatch = useDispatch();
  const clearFileInputRef = useRef(null);

  const [serviceRate, setServiceRate] = useState("");
  const [serviceRateType, setServiceRateType] = useState("FLAT");
  const [baseDeliveryCharge, setBaseDeliveryCharge] = useState(null);
  const [deliveryChargePerKm, setDeliveryChargePerKm] = useState(null);

  const [operatingLocation, setOperatingLocation] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonNumber, setContactPersonNumber] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const handleLoyalitySubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: "ADD_OPERATING_LOCATION_REQUEST",
      payload: {
        locationName: operatingLocation.toUpperCase(),
        operatingLocationImage: imageUrl,
        operatingLocationContactPersonName: contactPersonName,
        operatingLocationContactPersonNumber: contactPersonNumber,
        serviceType: serviceRateType,
        serviceFee: serviceRate,
        baseDeliveryCharge: baseDeliveryCharge,
        deliveryChargePerKm: deliveryChargePerKm,
      },
    });
  };
  useEffect(() => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", image);
    setLoading(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_FAKE}/foocery/v1/api/admin/operating-location/add-image`,
      // url: "https://app.gurugfood.com:8443/foocery/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setImageUrl(response.data.imageUrl);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [image]);

  useEffect(() => {
    let postsPerPage = 10;
    let currentPage = 1;
    if (isEnabledUser === "operatingLoAdded") {
      setOperatingLocation("");
      dispatch({ type: "ADD_OPERATING_LOCATION_MODAL" });
      dispatch({
        type: "FETCH_OPERATING_LOCATION_REQUEST",
        payload: { postsPerPage, currentPage },
      });
    }
  }, [isEnabledUser]);

  return (
    <div>
      <ToastContainer />
      <div className="Modal--heading">Add Operating Location</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Image : </label>
              <input
                type="file"
                ref={clearFileInputRef}
                name="image"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Operating Location Name: </label>
              <input
                type="text"
                value={operatingLocation}
                onChange={(e) => setOperatingLocation(e.target.value)}
                placeholder="Operating Location Name"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Contact Person Name: </label>
              <input
                type="text"
                value={contactPersonName}
                onChange={(e) => setContactPersonName(e.target.value)}
                placeholder="Contact Person Name"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Contact Person Number: </label>
              <input
                type="text"
                value={contactPersonNumber}
                onChange={(e) => setContactPersonNumber(e.target.value)}
                placeholder="Contact Person Number"
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Type: </label>
              <select
                value={serviceRateType}
                onChange={(e) => setServiceRateType(e.target.value)}
              >
                <option value="FLAT">FLAT</option>
                <option value="PERCENTAGE">PERCENTAGE</option>
              </select>
            </p>
          </div>
          {serviceRateType === "PERCENTAGE" ? (
            <div className="Modal--form--title">
              <p>
                <label htmlFor="title">Service Rate (%): </label>
                <input
                  type="number"
                  value={serviceRate}
                  onChange={(e) => setServiceRate(e.target.value)}
                  placeholder="Service Rate(%)"
                />
              </p>
            </div>
          ) : (
            <div className="Modal--form--title">
              <p>
                <label htmlFor="title">Service Rate(Flat): </label>
                <input
                  type="number"
                  value={serviceRate}
                  onChange={(e) => setServiceRate(e.target.value)}
                  placeholder="Service Rate(FLAT)"
                />
              </p>
            </div>
          )}

          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Base Delivery Charge($): </label>
              <input
                type="number"
                value={baseDeliveryCharge}
                onChange={(e) => setBaseDeliveryCharge(e.target.value)}
                placeholder="Base Delivery Charge"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Delivery Charge Per/KM($): </label>
              <input
                type="number"
                value={deliveryChargePerKm}
                onChange={(e) => setDeliveryChargePerKm(e.target.value)}
                placeholder="Delivery Charge Per/KM"
              />
            </p>
          </div>
          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleLoyalitySubmit}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddOperatingLocation);
