import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../../LoadingComponent";
import Footer from "../../Z_Footer/Footer";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import moment from "moment";
import DatePicker from "react-datepicker";
import RequestedPreOrderDetail from "../RequestedPreOrderDetail";
import dayjs from "dayjs";
import { useParams } from "react-router";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "./PdfDoc";
import { useLocation } from "react-router";
import { Tooltip } from "antd";

const mapStateToProps = (store) => {
  const {
    buttonText,
    isLoadingOnLogin,
    allPreOrderRequestedList,
    isToogleSidebar,
    isEnabledUsers,
    preOrderTimeSlotList,
  } = store.mainState;
  return {
    buttonText,
    isLoadingOnLogin,
    allPreOrderRequestedList,
    isToogleSidebar,
    isEnabledUsers,
    preOrderTimeSlotList,
  };
};

function PreOrderByIdList({
  buttonText,
  isLoadingOnLogin,
  allPreOrderRequestedList,
  isToogleSidebar,
  isEnabledUsers,
  preOrderTimeSlotList,
}) {
  const { preOrderId } = useParams();
  const myData = useLocation().state;

  const [showEditPreOrderModal, setShowEditPreOrderModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const [sortingParam, setSortingParams] = useState("descending");

  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDispatchModal, setShowDispatchModal] = useState(false);
  const [showDeclineModal, setShowDiclineModal] = useState(false);
  const [statusChangingItem, setStatusChanginItem] = useState(null);

  const [preOrderType, setPreOrderType] = useState("All");
  const dispatch = useDispatch();
  const [filterPreorderDate, setFilterPreorderDate] = useState("");
  const [finalPreorderDate, setFinalPreorderDate] = useState("");
  const [orderState, setOrderState] = useState("All");
  const [timeSlot, setTimeSlot] = useState(
    preOrderTimeSlotList?.filter(
      (timeSlot) =>
        timeSlot.slotDate == dayjs(filterPreorderDate).format("YYYY-MM-DD")
    )
  );
  const [selectedPreOrderTimeSlot, setSelectedPreOrderTimeSlot] = useState("");
  const [activeTimeSlot, setActiveTimeSlot] = useState("");
  useEffect(() => {
    let a = dayjs(filterPreorderDate).format("YYYY-MM-DD 00:00:00");
    if (filterPreorderDate) {
      setFinalPreorderDate(a);
    } else {
      setFinalPreorderDate("");
    }
    setTimeSlot(
      preOrderTimeSlotList?.filter(
        (timeSlot) =>
          timeSlot.slotDate == dayjs(filterPreorderDate).format("YYYY-MM-DD")
      )
    );
  }, [filterPreorderDate, preOrderTimeSlotList]);

  // Search pre order request
  const [myKeyword, setMyKeyword] = useState("");

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  let pageNumbers = [];

  for (let i = 1; i <= allPreOrderRequestedList.totalPage; i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    let deliveryTime = finalPreorderDate;
    dispatch({
      type: "GET_REQUESTED_PREORDER_LIST_REQUEST",
      payload: {
        postsPerPage,
        currentPage,
        deliveryTime,
        sortingParam,
        preOrderId,
        selectedPreOrderTimeSlot,
        orderState,
      },
    });
  }, [
    postsPerPage,
    currentPage,
    preOrderType,
    finalPreorderDate,
    sortingParam,
    orderState,
    selectedPreOrderTimeSlot,
  ]);

  useEffect(() => {
    dispatch({
      type: "GET_REQUESTED_PREORDER_LIST_REQUEST1",
      payload: {
        postsPerPage,
        currentPage,
        sortingParam,
        preOrderId,
        myKeyword,
      },
    });
  }, [myKeyword]);

  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };
  useEffect(() => {
    if (isEnabledUsers === "b") {
      setTimeout(() => {
        setShowApproveModal(false);
        setShowDiclineModal(false);
        setShowDispatchModal(false);
        window.location.reload();
      }, 100);
    }
  }, [isEnabledUsers]);
  useEffect(() => {
    dispatch({
      type: "FETCH_PRE_ORDER_TIME_SLOT_REQUEST",
      payload: { currentPage, postsPerPage, preOrderId },
    });
  }, [currentPage, postsPerPage]);

  console.log("This is issue", myData);

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Requested Pre Order By Id</h4>
        </div>

        <div className="user_groups">
          <div class="count-down-container" style={{ marginBottom: "1rem" }}>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="days">
                  {myData?.data?.todayData.todayDeliveryOrderCount}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Today Delivery Order{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="hours">{myData?.data?.todayData.todayOrderCount}</h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Today Order{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="minutes">
                  {myData?.data?.todayData.todayOrderQuantity
                    ? myData?.data?.todayData.todayOrderQuantity
                    : 0}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Today Order Quantity{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="seconds">
                  {myData?.data?.todayData.totalApprovedOrderCount}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Total Approved Order{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="seconds">
                  {myData?.data?.todayData.totalDispatchedOrderCount}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Total Dispatched Order{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="seconds">
                  {myData?.data?.todayData.totalRequestedOrderCount}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Total Requested Order{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="seconds">
                  {myData?.data?.totalSalesAmount
                    ? myData?.data?.totalSalesAmount
                    : 0}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Total Sales Amount{" "}
                </p>
              </div>
            </div>
            <div class="count-down-box">
              <div class="count-down">
                <h1 id="seconds">
                  {myData?.data?.totalSalesQuantity
                    ? myData?.data?.totalSalesQuantity
                    : 0}
                </h1>
                <p style={{ marginTop: "-3rem", margin: "5px" }}>
                  Total Sales Quantity{" "}
                </p>
              </div>
            </div>
          </div>
          <div
            className="user_groups--button_n_search"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <div className="user_groups--button_n_search--button">
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                Per Page
              </label>
              <select
                name="pageSize"
                onChange={(e) => setPostsPerPage(e.target.value)}
                style={{ width: "60px" }}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>

            <div className="user_groups--button_n_search--button">
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                Order State
              </label>
              <select
                name="pageSize"
                onChange={(e) => setOrderState(e.target.value)}
                style={{ width: "120px" }}
              >
                <option value={"All"}>All</option>
                <option value="REQUESTED">Requested</option>
                <option value="APPROVED">Approved</option>
                <option value="DISPATCH">Dispatched</option>
                <option value="DECLINED">Declined</option>
              </select>
            </div>
            <div
              className="pdf-download--btn"
              style={{ marginTop: "2rem", marginLeft: "2rem" }}
            >
              <PDFDownloadLink
                document={
                  <PdfDocument
                    finalPreorderDate={finalPreorderDate}
                    allPreOrderRequestedList={allPreOrderRequestedList}
                  />
                }
                fileName="Template.pdf"
                style={{ marginTop: "1rem" }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download Pdf"
                }
              </PDFDownloadLink>
            </div>
            <div
              className="user_groups--button_n_search--search"
              style={{
                marginTop: "17px",
                marginRight: "10px",
                position: "absolute",
                right: "40px",
              }}
            >
              <input
                type="text"
                value={myKeyword}
                placeholder="Search"
                onChange={(e) => {
                  setMyKeyword(e.target.value);
                }}
              />
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <hr />

            <div className="filter-by__date">
              <p>
                <label htmlFor="">
                  Filter By Delivery Date :{" "}
                  <DatePicker
                    placeholderText="Enter your date"
                    className="filter-by__Date--input"
                    dateFormat="yyyy-MM-dd"
                    selected={filterPreorderDate}
                    onChange={(date) => setFilterPreorderDate(date)}
                  />
                </label>{" "}
                <input
                  type="radio"
                  name="order-date"
                  style={{ marginLeft: "20px" }}
                  onChange={() => {
                    let a = new Date();
                    setFilterPreorderDate(a);
                  }}
                />{" "}
                <label htmlFor="order-date">Today</label>{" "}
                <input
                  style={{ marginLeft: "20px" }}
                  name="order-date"
                  type="radio"
                  onChange={() => {
                    const today = new Date();
                    const yesterday = new Date(today);

                    yesterday.setDate(yesterday.getDate() - 1);

                    setFilterPreorderDate(yesterday);
                  }}
                />{" "}
                <label htmlFor="order-date">Yesterday</label>{" "}
                {timeSlot?.length > 0 && (
                  <span style={{ marginLeft: "0.4rem" }}>
                    <label htmlFor="">Time Slot : </label>{" "}
                    <select
                      defaultValue={null}
                      placeholder="Select Time Slot"
                      name="pageSize"
                      onChange={(e) =>
                        setSelectedPreOrderTimeSlot(e.target.value)
                      }
                      style={{ width: "170px" }}
                    >
                      <option selected value="">
                        Select Time Slot
                      </option>
                      {timeSlot[0].timeResponses?.map((slot) => (
                        <option
                          onChange={() => {
                            setActiveTimeSlot(
                              `${slot.startTime} - ${slot.endTime}`
                            );
                          }}
                          value={slot.preOrderTimeSlotId}
                        >
                          {" "}
                          {slot.startTime} - {slot.endTime}
                        </option>
                      ))}
                    </select>{" "}
                  </span>
                )}{" "}
              </p>
            </div>

            <table className="table_x">
              <thead>
                <tr>
                  <th className="preOrder--name">PreOrderId</th>
                  <th className="preOrder--des">Plan Name</th>
                  <th className="preOrder--startDate">PreOrder Date</th>
                  <th className="preOrder--startDate">Delivery Date</th>
                  <th className="preOrder--contactname">Ordered By</th>
                  <th className="preOrder--contactNo">Contact Number</th>
                  <th className="preOrder--startDate">Location</th>
                  <th className="preOrder--startDate">Quantity</th>
                  <th className="preOrder--startDate">Amount</th>
                  <th className="preOrder--startDate">Delivery Price</th>
                  <th className="preOrder--startDate">Is Takeway</th>
                  <th className="preOrder--startDate">Pickup Location</th>
                  <th className="preOrder--startDate">Status</th>
                  <th className="preOrder--actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allPreOrderRequestedList !== undefined &&
                  allPreOrderRequestedList.data?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.preOrderId}</td>

                      <td>{item.preOrderPlanName}</td>
                      <td>{item.orderPlacedDateTime}</td>
                      <td>
                        {item.deliveryTime &&
                          getLocalDateTime(item.deliveryTime)}
                      </td>
                      <td>{item.fullName}</td>
                      <td>
                        <Tooltip
                          title={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>{item?.alternativeContactNumber}</span>
                              <span>{item?.email}</span>
                            </div>
                          }
                        >
                          {item.contactNumber}
                        </Tooltip>
                      </td>
                      <td
                        onClick={() =>
                          window.open(
                            `https://www.google.com/maps/search/?api=1&query=${item.deliveryLatitude},${item.deliveryLongitude}`
                          )
                        }
                        className="requestedPreorder--location"
                      >
                        {item.deliveryLocation}
                      </td>
                      <td>{item.quantity}</td>
                      <td>{item.paidAmount}</td>
                      <td>{item.deliveryPrice}</td>
                      <td>
                        {" "}
                        {item.isTakeAway ? (
                          <span
                            style={{
                              backgroundColor: "green",
                              padding: "3px 5px",
                              borderRadius: "4px",
                              color: "white",
                            }}
                          >
                            Pick up
                          </span>
                        ) : (
                          <span
                            style={{
                              backgroundColor: "green",
                              padding: "3px 5px",
                              borderRadius: "4px",
                              color: "white",
                            }}
                          >
                            Delivery
                          </span>
                        )}
                      </td>
                      <td
                        onClick={() =>
                          window.open(
                            `https://www.google.com/maps/search/?api=1&query=${item?.preferredTakeAwayStall?.stallLatitude},${item?.preferredTakeAwayStall?.stallLongitude}`
                          )
                        }
                        className="requestedPreorder--location"
                      >
                        {item?.preferredTakeAwayStall?.stallLocation}
                      </td>

                      <td>
                        <span
                          style={{
                            backgroundColor: "green",
                            padding: "3px 5px",
                            borderRadius: "4px",
                            color: "white",
                          }}
                        >
                          {item.orderState}
                        </span>
                      </td>
                      <td className="preorder--Action__btn">
                        <>
                          {item.orderState === "REQUESTED" && (
                            <button
                              onClick={() => {
                                setShowApproveModal(true);
                                setStatusChanginItem(item);
                              }}
                            >
                              Approve
                            </button>
                          )}
                          {item.orderState === "APPROVED" && (
                            <button
                              onClick={() => {
                                setShowDispatchModal(true);
                                setStatusChanginItem(item);
                              }}
                            >
                              Dispatch
                            </button>
                          )}

                          <button
                            onClick={() => {
                              setShowDiclineModal(true);
                              setStatusChanginItem(item);
                            }}
                          >
                            Decline
                          </button>
                        </>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <div
              className={`${
                showEditPreOrderModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <RequestedPreOrderDetail />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setShowEditPreOrderModal(false);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Edit</p>
                  </div>
                </div>
              </div>
            </div>
            {/* show pre order details */}
            {/* <div className={`${showPreorderDetailsModal ? "mymodal modal_activated" : "mymodal"}`}>
                            <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                                <PreOrderDetails detailShowingItem={detailShowingItem} />
                            </div>
                            <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                                <div className="task--rectangle__circle">
                                    <div className='circle'> <span onClick={() => {
                                        setShowPreOrderDetailsModal(false)
                                    }}><BsX /></span><p></p></div>
                                </div>
                            </div>
                        </div> */}
            {/* Approve pre-order */}
            <div
              className={`${
                showApproveModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading"></div>
                <>
                  <div className="delete--admin--container__body">
                    <h1>Are you sure to Approve ??</h1>
                    <div>
                      {isEnabledUsers === "a" && (
                        <div className="deleting--something">
                          {" "}
                          <p>Processing...</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "b" && (
                        <div className="deleting--something">
                          {" "}
                          <TiTick className="success--icon" /> <p>Successful</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "c" && (
                        <div className="deleting--something">
                          {" "}
                          <GiSkullCrossedBones
                            className="success--icon"
                            style={{ fontSize: "30px" }}
                          />{" "}
                          <p>Try Again</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() => setShowApproveModal(!showApproveModal)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={() => {
                        let id = statusChangingItem?.preOrderId;
                        dispatch({
                          type: "CHANGE_PREORDER_STATUS_REQUEST",
                          payload: {
                            id,
                            values: {
                              orderState: "APPROVED",
                              confirmationText: "ok",
                            },
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            {/* dispatch pre-order */}
            <div
              className={`${
                showDispatchModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading"></div>
                <>
                  <div className="delete--admin--container__body">
                    <h1>Are you sure to Dispatch ??</h1>
                    <div>
                      {isEnabledUsers === "a" && (
                        <div className="deleting--something">
                          {" "}
                          <p>Processing...</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "b" && (
                        <div className="deleting--something">
                          {" "}
                          <TiTick className="success--icon" /> <p>Successful</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "c" && (
                        <div className="deleting--something">
                          {" "}
                          <GiSkullCrossedBones
                            className="success--icon"
                            style={{ fontSize: "30px" }}
                          />{" "}
                          <p>Try Again</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() => setShowDispatchModal(!showDispatchModal)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={() => {
                        let id = statusChangingItem?.preOrderId;
                        dispatch({
                          type: "CHANGE_PREORDER_STATUS_REQUEST",
                          payload: {
                            id,
                            values: {
                              orderState: "DISPATCH",
                              confirmationText: "ok",
                            },
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            {/* decline pre-order */}
            <div
              className={`${
                showDeclineModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading"></div>
                <>
                  <div className="delete--admin--container__body">
                    <h1>Are you sure to Decline ??</h1>
                    <div>
                      {isEnabledUsers === "a" && (
                        <div className="deleting--something">
                          {" "}
                          <p>Processing...</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "b" && (
                        <div className="deleting--something">
                          {" "}
                          <TiTick className="success--icon" /> <p>Successful</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUsers === "c" && (
                        <div className="deleting--something">
                          {" "}
                          <GiSkullCrossedBones
                            className="success--icon"
                            style={{ fontSize: "30px" }}
                          />{" "}
                          <p>Try Again</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() => setShowDiclineModal(!showDeclineModal)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={() => {
                        let id = statusChangingItem?.preOrderId;
                        dispatch({
                          type: "CHANGE_PREORDER_STATUS_REQUEST",
                          payload: {
                            id,
                            values: {
                              orderState: "DECLINED",
                              confirmationText: "ok",
                            },
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>

            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > allPreOrderRequestedList.totalData
                  ? allPreOrderRequestedList.totalData
                  : indexOfLastPost}{" "}
                of {allPreOrderRequestedList.totalData} entries
              </p>
              <nav aria-label="...">
                <ul
                  className="pagination"
                  style={{ maxWidth: "400px", overflowX: "auto" }}
                >
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a
                        className="page-link"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a
                        className="page-link"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        onClick={() => {
                          setCurrentPage(x);
                          window.scrollTo(0, 0);
                        }}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a
                        className="page-link"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a
                        className="page-link"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(PreOrderByIdList);
